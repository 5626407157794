import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Company } from "src/app/model/company";
import { User } from "src/app/model/user";
import { CompanyService } from "src/app/service/company/company.service";
import { SnackService } from "src/app/service/snack/snack.service";
import { UserService } from "src/app/service/user/user.service";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../../../service/authentication/authentication.service";

@Component({
  selector: 'app-sonorize-parameters',
  templateUrl: './sonorize-parameters.component.html',
  styleUrls: [
    './sonorize-parameters.component.css',
    '../../../shared/css/user-modify-infos.style.css',
    '../../../../assets/sonorize-template/plugins/jvectormap/jquery-jvectormap-2.0.2.css',
    '../../../../assets/sonorize-template/plugins/lightpick/lightpick.css',
    '../../../../assets/sonorize-template/assets/css/bootstrap.min.css',
    '../../../../assets/sonorize-template/assets/css/jquery-ui.min.css',
    '../../../../assets/sonorize-template/assets/css/icons.min.css',
    '../../../../assets/sonorize-template/assets/css/metisMenu.min.css',
    '../../../../assets/sonorize-template/assets/css/app.min.css',
  ]
})

export class SonorizeParametersComponent implements OnInit {

  public user: User;
  public company: any;
  public registerForm: FormGroup;
  public logoName: string = '';
  public backgroundName: string = '';
  public initLogoUrl: string;
  public initBackgroundUrl: string;
  public selectedColor : any;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public isPreLoader: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder,
    private snack: SnackService,
  ) {

    this.authenticationService.user.subscribe(x => {
      this.user = x;
      console.log(this.user)
    });

    this.registerForm = this.formBuilder.group({
      name: [''],
      password: [''],
      email: [''],
      logo: [''],// Blob
      background: [''],// Blob
      color: [''],
      companyName: [''],
    });

  }

  public setColor(event){
    this.registerForm.controls.color.setValue(event.target.value);
    this.selectedColor = event.target.value;
  }

  public onSubmit() {

    if (this.registerForm.invalid) {
      return;
    }
    this.isPreLoader =true;
    console.log(this.registerForm.value, this.registerForm.controls)
    this.companyService.updateSonorizeUserCompany(this.registerForm.value, this.authenticationService.userValue.id)
      .pipe()
      .subscribe( {
        next: (response) => {
          this.isPreLoader =false;
          this.snack.info('Vos informations ont été enregistrées !');
        },
        error: (error) => {
          this.snack.error('Veuillez corriger les informations saisies !');
          this.isPreLoader = false;
        }
      });
  }

  public logoChangeEvent(event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      // console.log("FileUpload -> files", fileList);
      fileList[0].arrayBuffer().then(buffer => {
        //console.log(this._arrayBufferToBase64(buffer));
        this.registerForm.controls.logo.setValue(this._arrayBufferToBase64(buffer));
      });
      // console.log(fileList[0].name)
      this.logoName = fileList[0].name;
    }
  }

  public backgroundChangeEvent(event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      // console.log("FileUpload -> files", fileList);
      fileList[0].arrayBuffer().then(buffer => {
        //console.log(this._arrayBufferToBase64(buffer));
        this.registerForm.controls.background.setValue(this._arrayBufferToBase64(buffer));
      });
      // console.log(fileList[0].name)
      this.backgroundName = fileList[0].name;
    }
  }

  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return 'data:'+this.detectMimeType(window.btoa( binary ))+';base64,'+ window.btoa( binary );
  }
  detectMimeType(b64) {
    var signatures = {
      JVBERi0: "application/pdf",
      R0lGODdh: "image/gif",
      R0lGODlh: "image/gif",
      iVBORw0KGgo: "image/png",
      "/9j/": "image/jpg"
    };
    for (var s in signatures) {
      if (b64.indexOf(s) === 0) {
        return signatures[s];
      }
    }
  }

  ngOnInit(): void {

    // #TODO get params
    if(this.user) {
      this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
        this.company = data;
        console.log(this.company)
        this.initLogoUrl = this.imageBaseUrl + this.company.logo;
        this.initBackgroundUrl = this.imageBaseUrl + this.company.background;
        this.registerForm.setValue({
          name: this.user.name,
          password: '',
          email: this.user.email,
          logo: '',// Blob
          background: '',// Blob
          color: this.company.colorHex,
          companyName: this.company.name,
        });
        this.selectedColor = this.company.colorHex;
      });
    }


  }
}
