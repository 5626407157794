import { Pipe, PipeTransform } from '@angular/core';

export class cloudTag {
  tag: string;
  tendance: cloudTagTendance;
}
export type cloudTagTendance = '+' | '-' | 'n';



@Pipe({
  name: 'filterCloudTag',
  pure: false
})
export class FilterCloudTagPipe implements PipeTransform {
  transform(items: cloudTag[], tendance: cloudTagTendance): any {
      if (!items || !tendance) {
          return items;
      }
      // filter items array, items which match and return true will be
      // kept, false will be filtered out
      return items.filter(item => item.tendance.indexOf(tendance) !== -1);
  }
}
