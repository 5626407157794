import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Job } from '../../../model/job';
import { JobsService } from '../../../service/jobs/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobCategoriesService } from '../../../service/jobCategories/job-categories.service';
import { JobCategory } from '../../../model/jobCategory';
import { GoogleMap } from '@angular/google-maps';
import { environment } from '../../../../environments/environment';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { SnackService } from 'src/app/service/snack/snack.service';
import { Size } from '../../../shared/const/const';
import { SocialSharedComponent } from '../../../shared/social-shared/social-shared.component';
import { ModalCustomService } from '../../../service/dialog/modal-custom.service';
import { AudioFileService } from '../../../service/audiofile.service';
import { AudioService } from '../../../service/audio.service'
import { JobSinglePlayerService } from '../../../service/job-single-player/job-single-player.service';
import { Role } from '../../../model/role';
import { User } from '../../../model/user';
import { CompanyService } from '../../../service/company/company.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { JobQuestionsComponent } from '../job-questions/job-questions.component'
import { SharedServiceService } from '../../../shared/shared-service.service';
import { Platform } from '@angular/cdk/platform';
import { JobImageSuggestionComponent } from '../job-image-suggestion/job-image-suggestion.component';
import { JobTemplateAudioComponent } from '../job-template-audio-component/job-template-audio.component';


@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.css', '../../../../../node_modules/bootstrap/dist/css/bootstrap.css', '../../../../app/shared/css/single-job.style.css']
})
export class JobDetailComponent implements OnInit {
  @ViewChild('GoogleMap', { static: false }) map: GoogleMap;
  zoom = 12;
  center = {};
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  markerOptions = { icon: '../../../../assets/image/icon_metier_map.svg' };

  public job;
  public company;
  public isCompanyJob = false;
  public user: User;
  searchQuerry = 'aide';
  audioFile: File;
  jobImage: File;
  url = '';
  public showPlayer = false;
  public changeAudio = false;
  public types = [];
  public selectType;
  public selectCategory;
  public jobCategories: JobCategory[] = [];
  isOrderedJob = false;
  suggestAudio = '';

  state: any;
  val = true;
  show = false;

  private isSuggestion: boolean = false;
  private suggestionValue: string;

  public jobCategory: JobCategory;
  public jobs: any[] = [];
  public updateJob: Job;
  public jobForm: FormGroup;
  private audioSource: string;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public audioArray: any[] = [];
  constructor(
    private jobService: JobsService,
    private jobCategoryService: JobCategoriesService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private audioFileService: AudioFileService,
    private audioService: AudioService,
    private authenticationService: AuthenticationService,
    private snack: SnackService,
    private modalCustomService: ModalCustomService,
    private singlePlayerSvc: JobSinglePlayerService,
    private formBuilder: FormBuilder,
    private jobCategoriesService: JobCategoriesService,
    public dialog: MatDialog,
    private sharedService: SharedServiceService,
    private plt: Platform

  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    window.scrollTo(0, 0);
    const id = this.route.snapshot.params.job_id;
    this.getJob(Number(id));
    this.singlePlayerSvc.getState().subscribe(state => {
      this.state = state;
      state.readableCurrentTime == "" ? state.readableCurrentTime = "00:00" : "";
    });
    this.authenticationService.user.subscribe(x => this.user = x);
    this.isCompany();
  }

  ngOnInit(): void {
    this.getJobsTypes();
    this.getCategories();
    if (this.plt.ANDROID || this.plt.IOS) {
      this.sharedService.navigation.next(true);
      // this.sharedService.navigationToDetails.next(false);
    } else {
      this.sharedService.navigation.next(false);
      // this.sharedService.navigationToDetails.next(true);
    }


    // this.getLatLong(this.job.address);
    // console.log(this.job);
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(JobQuestionsComponent, {
      data: this.job.id
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  public isCompany(): boolean {
    return this.user && this.user.role === Role.Company;
  }

  public getLatLong(address: string): void {
    if (navigator.geolocation) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {

        if (status === google.maps.GeocoderStatus.OK) {
          this.options.mapTypeId = google.maps.MapTypeId.ROADMAP;
          const result = results[0];
          const lat = result.geometry.location.lat();
          const lng = result.geometry.location.lng();
          // alert(lat);
          // alert(lng);
          this.center = {
            lat,
            lng
          };

        }
      });
    }
  }

  public getJob(jobId): void {
    this.jobService.getJob(jobId).subscribe(data => {
      this.job = data;
      this.getSimilarJobs(this.job.id, this.job.jobCategoryId);
      if (this.user && this.isCompany()) {
        this.jobForm = this.formBuilder.group({
          id: [this.job.id],
          name: [this.job.jobName, [Validators.required]],
          urgent: [this.job.urgent, [Validators.required]],
          typeJob: [this.job.jobType],
          mission: [this.job.mission],
          profile: [this.job.profile],
          salary: [this.job.salary],
          JobCategoryId: [this.job.jobCategoryName],
          address: [this.job.address],
        });
        this.companyService.getCompanyByUser(this.user.id).subscribe(dataCompany => {
          this.company = dataCompany;
          this.isCompanyJob = this.company.id === this.job.companyId;
        }, ignore => {
          this.isCompanyJob = false;
          // this.snack.error('error when loading Job');
        });
      } else {
        this.isCompanyJob = false;
      }

      this.playStream(this.imageBaseUrl + this.job.audio);
      if (this.job.audio === null) {
        return;
      } else {
        if (this.job.audio[0] === '[') {
          this.job.audio = JSON.parse(this.job.audio)[0].download_link;
        }
      }
      this.playStream(this.imageBaseUrl + this.job.audio);
      this.getLatLong(this.job.address);
    }, ignore => {
      this.job = new Job();
      // this.snack.error('error when getting Job details');
    });
  }

  openSuggestionDialog(): void {
    const dialogRef = this.dialog.open(JobImageSuggestionComponent, {
      data: '',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      console.log(result);
      if (result !== undefined) {
        this.url = result;
        this.isSuggestion = true;
        const splitResult = result.split('/');
        this.suggestionValue = splitResult[splitResult.length - 1];
      }
    });
  }
  openTemplateAudioDialog(): void {
    const dialogRef = this.dialog.open(JobTemplateAudioComponent, {
      data: {
        job: this.job
      },
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((result: string) => {
      console.log({result});
      if (result !== undefined) {
       this.suggestAudio = result;
       this.isOrderedJob = false;
      }
    });
  }

  public getAudioLocation($urlAudio): string {
    if ($urlAudio.charAt(0) !== '[') {
      return $urlAudio;
    } else {
      this.audioArray = JSON.parse($urlAudio);
      // console.log(this.audioArray[0].download_link);
      return this.audioArray[0].download_link;
    }
  }
  public getSimilarJobs(jobId: number, categoryId: number): void {
    this.jobService.getSimilarJobs(jobId, categoryId).subscribe(data => {
      // console.log(data);
      this.jobs = data['similarJobs'];
    }, ignore => {
      this.jobs = [];
      // this.snack.error('error when loading similar Jobs');
    });
  }

  public abbJobToFavorite(jobId: number): void {
    this.jobService.addJobToFavorite(jobId, this.authenticationService.userValue.id).subscribe((data) => {
      this.snack.info('Job ajouté à vos favoris');
    }, (err) => {
      console.log(err);
    });
  }

  public openShareDialog(job: Job): void {
    this.modalCustomService.open({ width: Size.SMAL }, SocialSharedComponent, job);
  }

  public changeAudioStatut() {
    this.changeAudio = !this.changeAudio;
  }

  public getCompanyJobsOnJobs(): void {
    this.router.navigate(['/jobs'], {
      queryParams: { compagnyId: this.job.companyId }
    });
  }

  public playAudio(audioName: string, audioToPlay: string, job: any): void {
    this.audioFileService.addToPlayer(job);
    this.audioSource = audioToPlay;
    this.audioService.getAudioFile(audioName);
  }

  public pause(): void {
    this.singlePlayerSvc.pause();
  }

  public play(): void {
    this.singlePlayerSvc.play();
  }

  public stop(): void {
    this.singlePlayerSvc.stop();
  }

  public onSliderChangeEnd(change): void {
    this.singlePlayerSvc.seekTo(change.value);
  }

  public mute(): void {
    this.val = !this.val;
    this.singlePlayerSvc.mute(!this.val);
    this.show = false;
  }

  public onVolumeChangeEnd(change): void {
    // console.log(change);
    this.singlePlayerSvc.volume(change.value);
  }

  public playStream(url): void {
    this.singlePlayerSvc.playStream(url).subscribe(events => {
    });
    this.pause();
  }

  public onSelectAudioFile(event): void {
    if (event.target.files && event.target.files[0]) {
      this.audioFile = event.target.files[0];
    }
  }

  public onSelectFile(event): void {
    if (event.target.files && event.target.files[0]) {
      this.url = event.target.files[0];
      this.jobImage = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }
  public delete(): void {
    this.url = null;
  }
  public deleteRecord(): void {
    this.audioService.stop();
    this.audioFile = null;
    this.showPlayer = true;
    this.changeAudio = false;
    this.audioService.playStream(this.imageBaseUrl + this.job.audio).subscribe(events => {
    });
    this.audioService.pause();
  }

  public deleteRecordFile(): void {
    this.singlePlayerSvc.stop();
    this.audioFile = null;
    this.showPlayer = true;
    this.changeAudio = false;
    this.singlePlayerSvc.playStream(this.imageBaseUrl + this.job.audio).subscribe(events => {
    });
    this.singlePlayerSvc.pause();
  }

  public assignValue(e, libele): void {
    switch (libele) {
      case 'JobCategoryId':
        this.selectCategory = Number(e);
        this.jobForm.get(libele).setValue(this.jobCategories[e - 1].name);
        break;
      case 'typeJob':
        this.selectType = Number(e);
        this.jobForm.get(libele).setValue(this.types[e - 1].name);
        break;
      case 'address':
        this.jobForm.get(libele).setValue(e);
        this.getLatLong(this.jobForm.controls.address.value);
        break;
      default:
        this.jobForm.get(libele).setValue(e);
        console.log(this.jobForm.value);
    }
  }
  public buildAudioFile(value: File): void {
    console.log(value);
    this.audioFile = value;
  }
  public playRecord(file: any): void {
    this.playAudioRec(file.changingThisBreaksApplicationSecurity);
  }
  public playRecordFile(file: any): void {
    this.playAudioFile(file.changingThisBreaksApplicationSecurity);
  }
  public stopRec(show: boolean): void {
    this.audioService.stop();
    this.showPlayer = false;
  }

  public playAudioFile(url): void {
    this.showPlayer = true;
    this.audioService.playStream(url).subscribe(events => {
    });
  }

  public playAudioRec(url): void {
    this.audioService.playStream(url).subscribe(events => {
    });
  }
  private getJobsTypes(): void {
    this.jobService.getJobTypes().subscribe((data) => {
      this.types = data;
    });
  }

  public getCategories(): void {
    this.jobCategoriesService.getJobCategories().subscribe(data => {
      this.jobCategories = data;
      // console.log(this.jobCategories);
    }, ignore => {
      this.jobCategories = [];
      // this.snack.error('error when loading jobCategories');
    });
  }

  public record(): void {
    this.showPlayer = false;
  }

  public submit(): void {
    const sendData = new FormData();
    if (this.audioFile && this.changeAudio) {
      sendData.append('audio', this.audioFile);
    }
    if (this.isOrderedJob && this.changeAudio) {
      sendData.append('orderedAudio', '1');
    }
    if (this.suggestAudio !== '' && this.changeAudio) {
      sendData.append('suggestAudio',this.suggestAudio);
    }
    if (this.selectType) {
      sendData.append('jobTypeId', this.selectType);
    }
    if (this.selectCategory) {
      sendData.append('jobCategoryId', this.selectCategory);
    }
    sendData.append(this.isSuggestion ? 'selectedImage' : 'image', this.isSuggestion ? this.suggestionValue : this.jobImage);
    sendData.append('name', this.jobForm.controls.name.value);
    sendData.append('urgent', this.jobForm.controls.urgent.value);
    sendData.append('salary', this.jobForm.controls.salary.value);
    sendData.append('mission', this.jobForm.controls.mission.value);
    sendData.append('profile', this.jobForm.controls.profile.value);
    sendData.append('address', this.jobForm.controls.address.value);
    sendData.append('companyId', this.company.id);

    this.jobService.updateJob(this.job.id, sendData).subscribe((data) => {
      // console.log(data);
      this.snack.info('Offre bien modifiée !');
      this.router.navigateByUrl('/jobs');
      // this.back();
    }, (err) => {
      // console.log(err);
    });
  }

  public closePlayer(): void {
    this.audioService.stop();
    this.showPlayer = false;
  }

  public urgent0(): void {
    this.jobForm.controls.urgent.setValue(1);
    console.log(this.jobForm.controls.urgent.value);
  }

  public urgent1(): void {
    this.jobForm.controls.urgent.setValue(0);
    console.log(this.jobForm.controls.urgent.value);
  }
}
