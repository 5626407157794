import { Platform } from '@angular/cdk/platform';
import { Size } from './../../shared/const/const';
import { SocialSharedComponent } from './../../shared/social-shared/social-shared.component';
import { ModalCustomService } from './../../service/dialog/modal-custom.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AudioFileService } from './../../service/audiofile.service';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  AfterViewInit
} from '@angular/core';
import { JobCategory } from '../../model/jobCategory';
import { Job } from '../../model/job';
import { JobsService } from '../../service/jobs/jobs.service';
import { JobCategoriesService } from '../../service/jobCategories/job-categories.service';
import { NgxMasonryOptions } from 'ngx-masonry';
import { AudioService } from 'src/app/service/audio.service';
import { environment } from '../../../environments/environment';
import { debounceTime, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/service/authentication/authentication.service';
import { SnackService } from 'src/app/service/snack/snack.service';
import { SpeechToTextService } from '../../service/speech-to-text/speech-to-text.service';
import { fromEvent, Observable } from 'rxjs';
import { AgmMap, GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { ConnectionService } from 'ng-connection-service';
import { ItineraryOptionsService } from '../../service/itinerary-options/itinerary-options.service';
import { SharedServiceService } from '../../shared/shared-service.service';
import { TextToSpeechService } from 'src/app/service/text-to-speech/text-to-speech.service';
function _window(): any {
  return window;
}
declare var device;
declare var google: any;

interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
interface Locations {
  lat: number;
  lng: number;
  // tslint:disable-next-line:ban-types
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  marker?: Marker;
  options: any;
  jobId: number;
}
interface ISize { width: number; height: number; }

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: [
    './homepage.component.css', '../../shared/css/map-view.style.css', './../../../../node_modules/bootstrap/dist/css/bootstrap.css'
  ],
})
export class HomepageComponent implements OnInit, AfterViewChecked, AfterViewInit {
  public myOptions: NgxMasonryOptions = {
    // itemSelector: '.grid-item',
    columnWidth: 75,
    fitWidth: true,
    resize: true,
    itemSelector: '.grid-item'
  };
  public jobCategories: JobCategory[] = [];
  public jobs = [];
  public jobNumbers = 0;
  public searchForm: FormGroup;
  public markerIconClikedValue: number;
  public isRecord = true;
  public isAGMDirection = false;
  public renderOptions = {
    suppressMarkers: true,
    polylineOptions: { strokeColor: '#7b7c7c' },
  };
  public waypoints: any = [];
  public mapTransportMode = '';
  public mapDistanceDuration: string;
  public lastScrollTop: number = 0;


  public audioSource: string;
  public array = [1, 2];
  public masonryOptions: NgxMasonryOptions = {
    gutter: 20,
  };
  public imageBaseUrl: string = environment.imageBaseUrl;
  public currentLatitude = 47.351861;
  public currentLongitude = 0.6613099;
  public origin: any;
  public showHeaderToMap = true;

  public loadingJobs = false;
  public lastPage = null;
  public isPreLoader = false;
  @ViewChild(AgmMap) map: AgmMap;
  @ViewChildren('div') set divs(value: QueryList<ElementRef<HTMLTextAreaElement>>) {
    // For lifecycle error, use timeout
    setTimeout(() => this.speechToText.divs = value);
    // console.log(value);
  }
  @ViewChildren('contentDiv') dwPnl;
  paginatedJobs: any[] = [];
  jobPerPage = 8;

  zoom = 12;


  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    // maxZoom: 15,
    minZoom: 2,
  };
  markerOptions: any;
  geocoder: any;
  public location: Locations = {
    lat: 47.351861,
    lng: 0.6613099,
    marker: {
      lat: 47.351861,
      lng: 0.6613099,
      draggable: true
    },
    zoom: 5,
    options: {},
    jobId: null

  };
  audioUrl = 'assets/audio/intro.wav';
  circleRadius = 5000; // km
  userConnected: any;
  previous;
  public destination: any;
  private pageNumber = 1;
  public currentMapJobSelectedId: number;
  public isMobileDevice = false;
  deviceIsReady = true;
  // tslint:disable-next-line: new-parens
  recorder = new Object;
  public isBrowser = false;
  cities = [];
  city = new FormControl();
  searchCityLoading = false;


  constructor(
    public jobsService: JobsService,
    private jobCategoriesService: JobCategoriesService,
    private renderer: Renderer2,
    private audioService: AudioService,
    private authenticationService: AuthenticationService,
    private audioFileService: AudioFileService,
    private snack: SnackService,
    private speechToText: SpeechToTextService,
    private textToSpeech: TextToSpeechService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private modalCustomService: ModalCustomService,
    private cdRef: ChangeDetectorRef,
    public mapsApiLoader: MapsAPILoader,
    private zone: NgZone,
    private wrapper: GoogleMapsAPIWrapper,
    private connectionService: ConnectionService,
    public itineraryOptionsService: ItineraryOptionsService,
    private elem: ElementRef,
    private plt: Platform,
    private sharedService: SharedServiceService
    ) {
    /*this.sharedService.navigation.next(false);*/
    this.connectionService.monitor().subscribe((isConnected) => {
      console.log({isConnected});
    });

    // window.scrollTo(0, 0);
    if (this.route.snapshot.params.refresh === 1) {
      // window.location.reload();
    }
    this.mapsApiLoader = mapsApiLoader;
    this.zone = zone;
    this.wrapper = wrapper;
    this.mapsApiLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
    this.authenticationService.user.subscribe(x => this.userConnected = x);
    if (this.plt.isBrowser) {
      this.isBrowser = true;
    }

  }

    ngAfterViewChecked(): void {
      this.cdRef.detectChanges();
    }
  ngAfterViewInit(): void {
    this.options = google.maps.MapOptions = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
      // maxZoom: 15,
      minZoom: 2,
    };

    this.jobsService.isVoirCLick = true;
    if (this.plt.IOS || this.plt.ANDROID) {
      this.showHeaderToMap = false;
    } else {
      this.showHeaderToMap = true;
    }
  }

  ngOnInit(): void {
    this.location.marker.draggable = true;
    this.searchForm = this.formBuilder.group({
      querry: [''],
      city: [''],
      querryMap: ['']
    });
    //this.getLastJobs();
    //this.getCategories();
    //this.onChange();
    //this.getCurrentLatLong(null);
    //this.initCitySearch();
    document.addEventListener('deviceready', () => {
    }, false);
  }
  get divs(): QueryList<ElementRef<HTMLTextAreaElement>> {
    return this.speechToText.divs;
  }
  initCitySearch() {
    this.city.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.cities = [];
          this.searchCityLoading = true;
        }),
        switchMap(value => this.jobsService.searchCity(value)
  .pipe(
    finalize(() => {
      this.searchCityLoading = false
    }),
    )
    )
    )
    .subscribe((data: any) => {
      console.log(data)
      let results = data.predictions;
      results = results.filter(r => r.description.includes('France')).map(r => {
        return {
          name: r.structured_formatting.main_text,
          value: r.structured_formatting.main_text
        }
      })
      this.cities = results;

      console.log(this.cities);
    });
  }

  public getCurrentLatLong(params): void {
    if (params === null) {
      if (this.plt.ANDROID) {
        this.sharedService.navigationToDetails.next(true);
      }
      if (navigator) {
        navigator.geolocation.getCurrentPosition((pos) => {
          this.location.lng = +pos.coords.longitude;
          this.location.lat = +pos.coords.latitude;
          this.location.marker.lat = +pos.coords.latitude;
          this.location.marker.lng = +pos.coords.longitude;
          this.currentLatitude = +pos.coords.latitude;
          this.currentLongitude = +pos.coords.longitude;
          this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
        });
      }
    } else {
      if (this.plt.ANDROID) {
        this.sharedService.navigationToDetails.next(false);
      }
      if (navigator) {
        navigator.geolocation.getCurrentPosition((pos) => {
          this.location.lng = +pos.coords.longitude;
          this.location.lat = +pos.coords.latitude;
          this.location.marker.lat = +pos.coords.latitude;
          this.location.marker.lng = +pos.coords.longitude;
          this.currentLatitude = +pos.coords.latitude;
          this.currentLongitude = +pos.coords.longitude;
          this.markerOptions = { icon: 'assets/image/icon_localisation_map.svg' };
        });
      }
    }
  }

  public getDirection(destinationLatLong: any): void {
    this.isAGMDirection = true;
    this.origin = { lat: this.currentLatitude, lng: this.currentLongitude };
    this.destination = { lat: destinationLatLong.options.lat, lng: destinationLatLong.options.lng };
    this.getVehicle('DRIVING');
  }

  public getVehicle(transportMode): void {
    this.itineraryOptionsService.
    getVehicle(transportMode, this.currentLatitude, this.currentLongitude, this.destination.lat, this.destination.lng).then(data => {
      this.mapTransportMode = transportMode;
      this.mapDistanceDuration = data;
    });
  }
  // Map Click Marker
  public clickedMarker(infoWindow: any, jobId: number): void {
    this.isAGMDirection = false;
    this.mapTransportMode = '';
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infoWindow;
    this.currentMapJobSelectedId = jobId;
  }

  public removeItineraire(): void {
    this.isAGMDirection = false;
    this.mapTransportMode = '';
    if (this.previous) {
      this.previous.close();
    }
  }
  /**
  * get categories
  */
  public getCategories(): void {
    this.jobCategoriesService.getJobCategories().subscribe(data => {
      this.jobCategories = data;
      // console.log(this.jobCategories);
    }, ignore => {
      this.jobCategories = [];
      // this.snack.error('Error when loading jobCategories.');
    });
  }
  /**
  * get last jobs
  */
  public getLastJobs(): void {
    this.isPreLoader = true;
    // window.scrollTo(0, 0);
    this.pageNumber = 1;
    const userId = this.authenticationService.userValue === null
    ? null : this.authenticationService.userValue.id;
    this.loadingJobs = true;
    this.jobsService.getLastJobs(userId).subscribe((data: any) => {
      this.handleJobsLoading(data);
    }, ignore => {
      this.jobs = [];
      // this.snack.error('error when loading lastJobs.');
    });
  }

  public alreadyApplied() {
    this.snack.info("Vous avez déjà postulé à ce Job");
    this.textToSpeech.play("Vous avez déjà postulé à ce Job")
    return false;
  }

  private handleJobsLoading({lastJobs, totalJobs, lastPage}): void {
    // this.jobs = lastJobs;
    this.jobNumbers = totalJobs;
    lastJobs.map((job) => {
      this.getImgSize(this.imageBaseUrl + job.image).subscribe((res: any) => {
        if (res.height > res.width) {
          job.cardHeight = '320px';
        } else {
          job.cardHeight = '240px';
        }
      });
    });
    this.jobs.push(...lastJobs);
    this.paginatedJobs.push(...lastJobs);
    this.isPreLoader = false;
    this.loadingJobs = false;
  }

  public playAudio(audioName: string, audioToPlay: string, job: any): void {
    this.audioFileService.addToPlayer(job);
    this.audioSource = audioToPlay;
    this.audioService.getAudioFile(audioName);
  }

  public backClick(): void {
    this.options.mapTypeId = google.maps.MapTypeId.ROADMAP;
    this.jobsService.isVoirCLick = true;
    this.jobsService.mapButtonclicked = false;
    this.removeItineraire();
    if (this.plt.ANDROID || this.plt.IOS) {
      this.sharedService.navigationToDetails.next(true);
    }
  }
  public abbJobToFavorite(jobId: number, jobFavorite): void {
    if (this.authenticationService.userValue) {
      if (jobFavorite == 1) {
        this.snack.warn("Déjà ajouté à vos favoris");
      } else {
        this.jobsService.addJobToFavorite(jobId, this.authenticationService.userValue.id).subscribe((data) => {
          this.snack.info('Job ajouté à vos favoris.');
        }, (err) => {
          console.log(err);
        });
      }

    } else {
      this.snack.warn('Vous devez être connecté pour ajouter aux favoris.');
    }
  }
  public voiceSearch(objsIndex): void {
    this.isRecord = true;
    this.speechToText.voicesearch(objsIndex);
  }

  get f(): any {
    return this.searchForm.controls;
  }

  public searchWithoutCategory(): void {
    const textareaElement1 = this.divs.find((div, i) => i === 0);
    const voiceHandler1 = textareaElement1.nativeElement.value;

    const textareaElement2 = this.divs.find((div, i) => i === 1);
    const voiceHandler2 = textareaElement2.nativeElement.value;
    // const textareaElement3 = this.divs.find((div, i) => i === 2);
    // const voiceHandler3 = textareaElement3.nativeElement.value;
    const textareaElement3 = this.divs.find((div, i) => i === 2);

    const voiceHandler3 = textareaElement3 === undefined ? '' : textareaElement3.nativeElement.value;
    // tslint:disable-next-line: max-line-length
    voiceHandler3 === '' ? this.searchForm.setValue({ querry: voiceHandler1.trim(), city: voiceHandler2.trim(), querryMap: voiceHandler1.trim() }) : this.searchForm.setValue({ querry: voiceHandler3.trim(), city: voiceHandler2.trim(), querryMap: voiceHandler3.trim() });

    this.router.navigate(['/jobs'], {
      queryParams: {
        querry: this.f.querry.value, city: this.f.city.value, map: this.jobsService.isVoirCLick
      }
    });
  }

  public openShareDialog(job: Job, index: number): void {
    const mission = document.getElementsByClassName('job-mission')[0]['innerText'].split(' ')
    .splice(0, 10)
    .join(' ') + '...\n';
    if (this.plt.ANDROID || this.plt.IOS) {
      const options = {
        message: job.jobName + '\n\n' + mission, // not supported on some apps (Facebook, Instagram)
        subject: mission, // fi. for email
        files: [environment.imageBaseUrl + job.image], // an array of filenames either locally or remotely
        url: 'https://gotaf.fr/job/' + job.id,
        chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        // iPadCoordinates: '0,0,0,0' // IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
      };

      const onSuccess = (result) => {
        // alert('Share completed? ' + result.completed); // On Android apps mostly return false even while it's true
        // alert('Shared to app: ' + result.app);
      };
      const onError = (msg) => {
        // alert(msg);
      };

      _window().plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
    } else {
      this.modalCustomService.open({ width: Size.SMAL }, SocialSharedComponent, job);
    }
  }

  public openYoutubeDialog(): void {
    this.audioService.playStream(this.audioUrl).subscribe(events => {
    });
    // this.modalCustomService.open({ width: Size.LARGE }, YoutubeComponent, null);
  }

  public onChange(): void {
    this.searchForm.valueChanges.subscribe((data) => { });
  }
  public showUrgentJob(e): void {
    console.log(e);
    e.map((job) => {
      this.getImgSize(this.imageBaseUrl + job.image).subscribe((res: any) => {
        if (res.height > res.width) {
          job.cardHeight = '320px';
        } else {
          job.cardHeight = '240px';
        }
      });
    });
    this.jobs = e;
    this.pageNumber = 1;
    this.paginatedJobs = this.jobs.slice((this.pageNumber - 1) * this.jobPerPage, this.jobPerPage * this.pageNumber);
  }

  public saveToSearch(): void {
    if (this.authenticationService.userValue) {
      const sendData = new FormData();
      sendData.append('userId', this.authenticationService.userValue.id.toString());
      sendData.append('searchWord', this.f.querry.value);
      sendData.append('searchTown', this.f.city.value);
      this.jobsService.saveUserSearch(sendData).subscribe((data) => {
        console.log(sendData);
        this.snack.info('vos paramètres de recherches ont été enregistrés.');
      }, (err) => {
        this.snack.error('Erreur lors de l\'enregistrement de vos paramètres de recherches.');
      });
    } else {
      this.snack.warn('Veuillez vous connecter.');
    }
  }

  private getImgSize(imageSrc: string): Observable<ISize> {
    // console.log(imageSrc);
    const mapLoadedImage = (event: any): ISize => {
      return {
        width: event.target.width,
        height: event.target.height
      };
    };
    const image = new Image();
    const $loadedImg = fromEvent(image, 'load').pipe(take(1), map(mapLoadedImage));
    image.src = imageSrc;
    return $loadedImg;
  }

  public updateMapView(job: any): void {
    this.location.lat = job.options.lat;
    this.location.lng = job.options.lng;
    this.location.zoom = 20;
  }

  public focusOneJobs(id: number): void {
    this.markerIconClikedValue = id;
  }

  /*public applyToJob(jobId: number): void {
    if (!this.userConnected.hasAudio) {
      this.router.navigate(['/user-cv-create'], { queryParams: { returnUrl: '/job-application/' + jobId } });
    } else {
      this.router.navigate(['/job-application/' + jobId]);
    }
  }*/

  public onScroll(): void {

    var st = window.pageYOffset || document.documentElement.scrollTop;
    let isScrollingBottom = false;
    /*console.log('lST'+this.lastScrollTop+'st'+st);*/
   if (st > this.lastScrollTop){
     let footerHeight: number = document.getElementsByTagName('footer')[0].clientHeight;
     /*console.log('scrolling down');
     console.log('inH'+window.innerHeight+'scY'+window.scrollY+'pYo'+window.pageYOffset+'oH'+document.body.offsetHeight);*/
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight-footerHeight) {
      isScrollingBottom = true;
      /*console.log('its bottom');*/
    }
   }
   this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

    if (this.loadingJobs || ( this.lastPage && this.pageNumber >= this.lastPage) || !isScrollingBottom) {
      return;
    }
    this.pageNumber++;
    const userId = this.authenticationService.userValue === null
    ? null : this.authenticationService.userValue.id;
    this.loadingJobs = true;
      this.isPreLoader = true;
      this.jobsService.getLastJobs(userId, {page: this.pageNumber}).subscribe((data: any) => {
        console.log("this.pageNumber123==>");
        console.log(this.pageNumber);
        // this.jobsService.scrollJob = 1;
        this.handleJobsLoading(data);
      }, ignore => {
      this.jobs = [];
      });
  }
  resizeGridItem(item: any): void {
    console.log(item);
    const grid = document.getElementsByClassName('dw')[0];
    const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'), 10);
    const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'), 10);
    const rowSpan = Math.ceil((item.querySelector('.contents').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
    item.style.gridRowEnd = 'span ' + rowSpan;
  }

  private resizeAllGridItems(): void {
  }

  // tslint:disable-next-line: typedef
  isNull(data) {
    return data.salary === null || data.salary === '0.00';
  }
}
