import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '../../../service/snack/snack.service';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { AudioService } from '../../../service/audio.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmCodeComponent } from '../confirm-code/confirm-code.component';
import { Platform } from '@angular/cdk/platform';
// import { SignInWithApple } from 'cordova-plugin-apple-login'
declare var FCMPlugin: any;

function _window(): any {
  return window;
}
@Component({
  selector: 'app-signup-sonorize',
  templateUrl: './signup-sonorize.component.html',
  styleUrls: ['./signup-sonorize.component.css']
})
export class SignupSonorizeComponent implements OnInit {
  public user: any;
  public registerForm: FormGroup;
  public isFirst = true;
  public submitted = false;
  private audioUrl = 'assets/audio/inscription-user.wav';
  public showPlayer = false;
  public buttonText = 'Valider';
  public isEntreprise = false;
  public isIOSDevice = false;
  public isMobileDevice = false;
  public deviceToken = "";
  public deviceType :any;
  public selectedColor : any;
  public logoName: string = '';
  public isSended: boolean = false;
  public isPreLoader: boolean = false;
  public backgroundName: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snack: SnackService,
    private socialService: SocialAuthService,
    private audioService: AudioService,
    private matDialog: MatDialog,
    private plt: Platform
  ) {}


  ngOnInit(): void {

    this.authenticationService.user.subscribe(x => {
      this.user = x;

      console.log(this.user)
      if(this.user/* && isSonorizeUser #TODO test if sonorize user */) {
        this.router.navigateByUrl('/sonorize');
      }
    });
    this.registerForm = this.formBuilder.group({
      role_id: [false, Validators.required],
      name: [''],
      password: ['', Validators.required],
      email: ['', Validators.required],
      logo: ['', Validators.required],// Blob
      background: ['', Validators.required],// Blob
      punchLine:['', Validators.required],
      color: [''],
      companyName: [''],
      deviceToken: [''],
      userPhone: ['0606060606'],
      companySiret: ['non_renseigne'],
      active: [false],
      // deviceToken: ['',Validators.required],
      deviceType: [''],
      // deviceType: ['',Validators.required],
    });
    this.setStateUserType(true);
    this.registerForm.controls.role_id.valueChanges.subscribe((data) => {
      console.log(data);
      if (data === false) {
        this.audioUrl = 'assets/audio/inscription-user.wav';
      } else {
        this.audioUrl = 'assets/audio/inscription-company.wav';
      }
    });

  }

  public setColor(event){
    this.registerForm.controls.color.setValue(event.target.value);
  }

  public setActive(event) {
    this.registerForm.controls.active.setValue(event.target.checked);
  }
  public logoChangeEvent(event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      // console.log("FileUpload -> files", fileList);
      fileList[0].arrayBuffer().then(buffer => {
        //console.log(this._arrayBufferToBase64(buffer));
        this.registerForm.controls.logo.setValue(this._arrayBufferToBase64(buffer));
      });
      // console.log(fileList[0].name)
      this.logoName = fileList[0].name;
    }
  }

  public backgroundChangeEvent(event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      // console.log("FileUpload -> files", fileList);
      fileList[0].arrayBuffer().then(buffer => {
        //console.log(this._arrayBufferToBase64(buffer));
        this.registerForm.controls.background.setValue(this._arrayBufferToBase64(buffer));
      });
      // console.log(fileList[0].name)
      this.backgroundName = fileList[0].name;
    }
  }

  _arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  get f(): { [p: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  public setStateUserType(type: boolean): void {
    this.isEntreprise = type;
    this.registerForm.controls.role_id.setValue(type);
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.registerForm.invalid || ((this.registerForm.value.role_id === true && this.registerForm.controls.companyName.value === ''))) {
      return;
    }
    this.isPreLoader =true;

    this.registerForm.controls.role_id.setValue('3');

    this.registerForm.controls.deviceToken.setValue(this.deviceToken);
    this.registerForm.controls.deviceType.setValue(this.deviceType);
    if (this.registerForm.value.role_id === '3') {
      this.authenticationService.register(this.registerForm.value)
        .pipe(first())
        .subscribe( {
          next: (response) => {
            // console.log(response);
            this.isSended = true;
            this.isPreLoader =false;
            // #Todo create widget job and display informations
            // get return url from query parameters or default to home page
            /*const returnUrl = this.route.snapshot.queryParams.returnUrl || '/company-job-applied';
            this.router.navigateByUrl(returnUrl);*/
            this.snack.info('Bienvenue sur gotaf!'); // TODO: add user firstname on message
          },
          error: (error) => {
            this.snack.error('Le mail n\'est pas valide où bien il est déjà enregistré.');
            this.isPreLoader = false;
          }
        });
    }
  }

  public playAudio(): void {
    this.showPlayer = true;
    this.audioService.playStream(this.audioUrl).subscribe(events => {
    });
  }

  public playStream(url): void {
    this.audioService.playStream(url).subscribe(events => {
    });
  }

  public closePlayer(): void {
    this.audioService.stop();
    this.showPlayer = false;
  }
}

