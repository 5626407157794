import { Component, OnInit } from '@angular/core';
import {User} from '../../../model/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment.prod';
import {AuthenticationService} from '../../../service/authentication/authentication.service';
import {UserService} from '../../../service/user/user.service';
import { JobsService } from 'src/app/service/jobs/jobs.service';
import {Router} from '@angular/router';
import {SnackService} from '../../../service/snack/snack.service';
import {formatDate} from '@angular/common';
import {Company} from '../../../model/company';
import {CompanyService} from '../../../service/company/company.service';
import { FormControl } from '@angular/forms';
//import { Airtable, Base, Table as AirtableTable } from 'ngx-airtable';
//import { NgChartjsModule } from 'ng-chartjs';
import { ChartDataset, ChartType, ChartOptions, beforeDraw, Chart } from 'chart.js';
import { Base as _Base, Table, FieldSet } from 'airtable';
import Airtable from 'airtable';

type Radar = {
  tag: string;
  value: string;
}

@Component({
  selector: 'app-sonorize-verbatims',
  templateUrl: './sonorize-verbatims.component.html',
  styleUrls: ['./sonorize-verbatims.component.css', '../../../shared/css/user-modify-infos.style.css', './../../../../../node_modules/bootstrap/dist/css/bootstrap.css']
})

export class SonorizeVerbatimsComponent implements OnInit {
  public user: any;
  public company: Company;
  public companyInformation: FormGroup;
  public imageBaseUrl: string = environment.imageBaseUrl;
  public campaigns: any;
  public selectedCampaignValue: string = "";
  //public airtableBase: Base;
  public _airtableBase: _Base;
  public isAddCampaign: boolean = false;

  // for radar chart
  //public graphRadarTable: AirtableTable;
  public _graphRadarTable: Table<FieldSet>;
  public radarChartData: Array<any>;// ChartDataset[];
  public radarChartType: any;//ChartType;
  public radarChartLabels: string[];
  public radarChartOptions: any;//ChartOptions ;
  public isRadarChartLoaded: boolean = false;
  // for marco themes radar chart
  //public graphRadarMacroTable: AirtableTable;
  public _graphRadarMacroTable: Table<FieldSet>;
  public radarChartMacroData: Array<any>;
  public radarChartMacroLabels: string[];
  public isRadarChartMacroLoaded: boolean = false;
  // for label themes radar chart
  //public graphRadarLabelTTable: AirtableTable;
  public _graphRadarLabelTTable: Table<FieldSet>;
  public radarChartLabelTData: Array<any>;
  public radarChartLabelTLabels: string[];
  public isRadarChartLabelTLoaded: boolean = false;

  public addWidgetForm: FormGroup;
  public isPreLoader: boolean = false;

  companyLogo: File;
  url = '';
  userStored = JSON.parse(localStorage.getItem('user'));
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private jobService: JobsService,
    private companyService: CompanyService,
    private router: Router,
    private snack: SnackService,
    //private airtable: Airtable,
    //private _airtable: Airtable,
  ) {
    Airtable.configure({
      endpointUrl: 'https://api.airtable.com',
      apiKey: 'patMcW6GBzRWgTu9a.1222808019f493aae93f4cea5cdebe1f97e53725e79542abcb4beb9d648e9ac2'
    });
    this.authenticationService.user.subscribe(x => this.user = x);
    this.user = this.userStored;
    //this.airtableBase = this.airtable.base('appgP1gizNrSFPR1j');
    this._airtableBase = new Airtable({apiKey: 'patMcW6GBzRWgTu9a.1222808019f493aae93f4cea5cdebe1f97e53725e79542abcb4beb9d648e9ac2'}).base('appgP1gizNrSFPR1j');
    this.radarChartOptions = {
      responsive: true,
    };

    this.radarChartLabels =  ['', '', '', '', ''];
    this.radarChartMacroLabels =  ['', '', '', '', ''];
    this.radarChartLabelTLabels = ['', '', '', '', ''];

    this.radarChartData = [
      { data: [0,0,0,0,0], label: 'Tags' },
    ];
    this.radarChartMacroData = [
      { data: [0,0,0,0,0], label: 'Tags' },
    ];
    this.radarChartLabelTData = [
      { data: [0,0,0,0,0], label: 'Tags' },
    ];

    this.radarChartType = 'radar';
  }

  phoneNumberPattern = '^[0-9 ]*$';
  // isFirstBlockErrorShow = false;
  // isSecondBlockErrorShow = false;
  ngOnInit(): void {
    console.log('init verbatims campaign')
    if(!(this.user))
    {
      // # TODO redirect to login
    }
    this.getCompany();
    this.addWidgetForm = this.formBuilder.group({
      punchLine:['', Validators.required],
      active: [false],
      userId: this.user.id,
    });
    /*this.companyService.getCompanyByUser(this.user.id).subscribe(dataCompany => {
      this.company = dataCompany;
      if (this.company) {
        this.companyInformation.controls.name.setValue(this.company.name);
        this.companyInformation.controls.siret.setValue(this.company.siret);
        this.companyInformation.controls.summary.setValue(this.company.summary);
        this.companyInformation.controls.website.setValue(this.company.website);
        this.companyInformation.controls.phone.setValue(this.company.phone);
        this.companyInformation.controls.address.setValue(this.company.address);
        this.companyInformation.controls.facebook.setValue(this.company.facebook);
        this.companyInformation.controls.linkedIn.setValue(this.company.linkedIn);
      }
    });*/
  }

  public onSubmit(): void {
    if (this.addWidgetForm.invalid) {
      return;
    }
    this.isPreLoader =true;
    this.jobService.createWidgetJobAudio(this.addWidgetForm.value).subscribe((data) => {
      this.company && this.getCompanyJobs(this.company.id);
      this.isPreLoader = false;
      this.toggleShowAddCampaign();
      this.snack.info('Votre nouvelle campagne a bien été créée !'); // TODO: add user firstname on message
    });
  }

  public setActive(event) {
    this.addWidgetForm.controls.active.setValue(event.target.checked);
  }

  public toggleShowAddCampaign() {
    this.isAddCampaign = !this.isAddCampaign;
  }


  public changeCampaign(id) {
    this.selectedCampaignValue = id;
    this.loadRadarGraphFromAirTable(id);
    this.loadRadarGraphMacroFromAirTable(id);
    this.loadRadarGraphLabelTFromAirTable(id);
  }

  public getCompany(): void {
    this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
      this.company = data;
      this.getCompanyJobs(this.company.id);
    }, ignore => {
      console.log('error when loading company data'); // TODO: change here by a fancy snack to display error
    });
  }

  public getCompanyJobs(companyId): void {
    this.companyService.getCompanyJobs(companyId).subscribe((data: any) => {
      this.campaigns = data['companyJobs'];
      if(this.campaigns) {
        // this.selectedCampaignValue = this.campaigns[0].id;
      }
    }, ignore => {
      this.campaigns = [];
      console.log('error when loading campaigns'); // TODO: change here by a fancy snack to display error
    });
  }

  public loadRadarGraphFromAirTable(campaignId: string) {
    this.isRadarChartLoaded = true;
    //#TODO load data from airtable (using ngx-airtable and api doc is here https://airtable.com/appgP1gizNrSFPR1j/api/docs#javascript/table:graphradar:list)
    //this.graphRadarTable = this.airtableBase.table({tableId:'GraphRadar'});
    this._graphRadarTable = this._airtableBase.table('GraphRadar');
    console.log(this._graphRadarTable);

    let radarData: Radar[] = [];
    let tags:string[] = [];
    let values:number[] = [];

    /*this.graphRadarTable.select({
      maxRecords: 10000,
      view: "Grid view"
    }).eachPage().forEach( async (page) => {*/
    this._graphRadarTable.select({
      maxRecords: 10000,
      view: "Grid view"
    }).eachPage((page, index) => {
      // console.log(page);
      for(let i= 0 ; i < page.length; i++) {
        // console.log(page[i].fields.idCampagne.toString(), campaignId);
        if(page[i].fields.idCampagne && page[i].fields.idCampagne.replace(/ /g,'') == campaignId) {
          console.log(page[i].fields.Tag)
          console.log(page[i].fields.Valeur)
          radarData.push( { tag: page[i].fields.Tag || '', value: page[i].fields.Valeur || ''} );
          if(page[i].fields.Tag && page[i].fields.Valeur) {
            tags.push(page[i].fields.Tag);
            values.push(page[i].fields.Valeur);
          }
        }
        /*if(i == page.length-1) {
          console.log("loading...", values)
          this.radarChartLabels = tags;
          this.radarChartData = [
            { data: values, label: 'Tags' }
          ];
        }*/
      }
      this.radarChartLabels = tags;
      this.radarChartData = [
        { data: values, label: 'Tags' }
      ];
      console.log(values);
      console.log(radarData);
    });

    //#TODO restitute data in four chartjs graphs
  }

  public loadRadarGraphLabelTFromAirTable(campaignId: string) {
    this.isRadarChartLabelTLoaded = true;
    //#TODO load data from airtable (using ngx-airtable and api doc is here https://airtable.com/appgP1gizNrSFPR1j/api/docs#javascript/table:graphradar:list)
    //this.graphRadarLabelTTable = this.airtableBase.table({tableId:'GraphRadarLabelTheme'});
    this._graphRadarLabelTTable = this._airtableBase.table('GraphRadarLabelTheme');
    console.log(this._graphRadarLabelTTable);
    let radarData: Radar[] = [];
    let tags:string[] = [];
    let values:number[] = [];

    /*this.graphRadarLabelTTable.select({
      maxRecords: 10000,
      view: "Grid view"
    }).eachPage().forEach((page) => {*/
    this._graphRadarLabelTTable.select({
      maxRecords: 10000,
      view: "Grid view"
    }).eachPage((page, index) => {
      // console.log(page);
      for(let i= 0 ; i < page.length; i++) {
        // console.log(page[i].fields.idCampagne.toString(), campaignId);
        if(page[i].fields.idCampagne && page[i].fields.idCampagne.replace(/ /g,'') == campaignId) {
          console.log(page[i].fields.Tag)
          console.log(page[i].fields.Valeur)
          radarData.push( { tag: page[i].fields.Tag || '', value: page[i].fields.Valeur || ''} );
          if(page[i].fields.Tag && page[i].fields.Valeur) {
            tags.push(page[i].fields.Tag);
            values.push(page[i].fields.Valeur);
          }
        }
      }
      this.radarChartLabelTLabels = tags;
      this.radarChartLabelTData = [
        { data: values, label: 'Tags' }
      ];
      console.log(values);
      console.log(radarData);
    });
  }

  public loadRadarGraphMacroFromAirTable(campaignId: string) {
    this.isRadarChartMacroLoaded = true;
    //#TODO load data from airtable (using ngx-airtable and api doc is here https://airtable.com/appgP1gizNrSFPR1j/api/docs#javascript/table:graphradar:list)
    //this.graphRadarMacroTable = this.airtableBase.table({tableId:'GraphRadarMacroTheme'});
    this._graphRadarMacroTable = this._airtableBase.table('GraphRadarMacroTheme');
    console.log(this._graphRadarMacroTable);
    let radarData: Radar[] = [];
    let tags:string[] = [];
    let values:number[] = [];

    /*this.graphRadarMacroTable.select({
      maxRecords: 10000,
      view: "Grid view"
    }).eachPage().forEach((page) => {*/
    this._graphRadarMacroTable.select({
      maxRecords: 10000,
      view: "Grid view"
    }).eachPage((page, index) => {
      // console.log(page);
      for(let i= 0 ; i < page.length; i++) {
        // console.log(page[i].fields.idCampagne.toString(), campaignId);
        if(page[i].fields.idCampagne && page[i].fields.idCampagne.replace(/ /g,'') == campaignId) {
          console.log(page[i].fields.Tag)
          console.log(page[i].fields.Valeur)
          radarData.push( { tag: page[i].fields.Tag || '', value: page[i].fields.Valeur || ''} );
          if(page[i].fields.Tag && page[i].fields.Valeur) {
            tags.push(page[i].fields.Tag);
            values.push(page[i].fields.Valeur);
          }
        }
      }
      this.radarChartMacroLabels = tags;
      this.radarChartMacroData = [
        { data: values, label: 'Tags' }
      ];
      console.log(values);
      console.log(radarData);
    });
  }




}
