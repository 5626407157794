import { Component, OnInit } from "@angular/core";
import { User } from "src/app/model/user";
import { CompanyService } from "src/app/service/company/company.service";
import { AuthenticationService } from "../../../service/authentication/authentication.service";
import $ from 'jquery';
import { Base as _Base, Table, FieldSet } from 'airtable';
import Airtable from 'airtable';

@Component({
  selector: 'app-sonorize-reviews',
  templateUrl: './sonorize-reviews.component.html',
  styleUrls: [
    './sonorize-reviews.component.css',
    '../../../shared/css/user-modify-infos.style.css',
    '../../../../assets/sonorize-template/plugins/jvectormap/jquery-jvectormap-2.0.2.css',
    '../../../../assets/sonorize-template/plugins/lightpick/lightpick.css',
    '../../../../assets/sonorize-template/assets/css/bootstrap.min.css',
    '../../../../assets/sonorize-template/assets/css/jquery-ui.min.css',
    '../../../../assets/sonorize-template/assets/css/icons.min.css',
    '../../../../assets/sonorize-template/assets/css/metisMenu.min.css',
    '../../../../assets/sonorize-template/assets/css/app.min.css',
  ]
})

export class SonorizeReviewsComponent implements OnInit {

  public user: User;
  public uReviews: any;
  public _airtableBase: _Base;
  public _cloudTagTable: Table<FieldSet>;

  constructor(
    private authenticationService: AuthenticationService,
    private companyService: CompanyService,
  ) {

    Airtable.configure({
      endpointUrl: 'https://api.airtable.com',
      apiKey: 'patMcW6GBzRWgTu9a.1222808019f493aae93f4cea5cdebe1f97e53725e79542abcb4beb9d648e9ac2'
    });
    this._airtableBase = new Airtable({apiKey: 'patMcW6GBzRWgTu9a.1222808019f493aae93f4cea5cdebe1f97e53725e79542abcb4beb9d648e9ac2'}).base('appgP1gizNrSFPR1j');

    this.authenticationService.user.subscribe(x => {
      this.user = x;
    });

  }

  public toggleUReviews(wid, urlength) {
    for(let i=0;i < urlength-1; i++) {
      $('#urev-'+wid+'-'+i).toggle();
    }
  }

  ngOnInit(): void {

    // #TODO get widgets
    if(this.user) {
      this.companyService.getCompanyReviews(this.authenticationService.userValue.id).subscribe((data) => {
        this.uReviews = data;

        let script = document.createElement('script');
        script.type = `text/javascript`;
        this._cloudTagTable = this._airtableBase.table('CloudTag');

        let qrtxt = "";
        if(this.uReviews && this.uReviews.jobs) {
          let $this = this;
          this.uReviews.jobs.forEach(function (job, jobIndex) {
            console.log(job.id, $this._cloudTagTable)
            $this.uReviews.jobs[jobIndex].cloudTags = [];
            $this._cloudTagTable.select({
              filterByFormula: `{IdCampagne} = "${job.id}"`,
              /*maxRecords: 10000,
              view: "Grid view"*/
            }).eachPage((page, index) => {
              for(let i= 0 ; i < page.length; i++) {
                if($this.uReviews.jobs[jobIndex] !== undefined) {
                  $this.uReviews.jobs[jobIndex].cloudTags.push({tag: page[i].fields.Tag, tendance: page[i].fields.TendanceTheme});
                }
              }
              console.log($this.uReviews.jobs[jobIndex].cloudTags.filter(t => t.tendance === '+'))
            });
           qrtxt += `$('#qr-`+job.id+`').qrcode({
            'render' : 'canvas',
            'text' : '`+job.widgetUrl+`',
            'size' : 76,// image width in pixel
            });
            toggleUReviews('`+job.id+`','`+job.userJobs.length+`');`;
          });

        script.text = `
        var intr = setInterval(function() {

          if ($ != undefined && $('#qr-`+this.uReviews.jobs[0].id+`').qrcode != undefined) {

            function toggleUReviews(wid, urlength) {
              for(let i=0;i < urlength-1; i++) {
                $('#urev-'+wid+'-'+i).toggle();
              }
            }
            $(document).ready(function() {

              setTimeout(function() {
                `+qrtxt+`
              }, 500);
            });
            clearInterval(intr);
          }
        }, 300);

        `;
        document.body.appendChild(script);
        }
        console.log(data)
      });
    }

    /*let scriptjq = document.createElement('script');
    scriptjq.type = `text/javascript`;
    scriptjq.src = '../../../../assets/sonorize-template/assets/js/jquery.min.js';
    let $this = this;
    scriptjq.addEventListener('load', function() {

        let scriptqr = document.createElement('script');
        scriptqr.type = `text/javascript`;
        scriptqr.src = 'https://larsjung.de/jquery-qrcode/latest/jquery-qrcode-0.18.0.js';
        document.body.appendChild(scriptqr);
        scriptqr.addEventListener('load', function() {
          let script = document.createElement('script');
          script.type = `text/javascript`;

          let qrtxt = "";
          if($this.uReviews && $this.uReviews.jobs) {
            $this.uReviews.jobs.forEach(function (job) {
             qrtxt += `$('#qr-`+job.id+`').qrcode({
              'render' : 'canvas',
              'text' : '`+job.widgetUrl+`',
              'size' : 76,// image width in pixel
              });`;
            });
          }
          script.text = `

                $(document).ready(function() {
                  `+qrtxt+`

              });

          `;
          document.body.appendChild(script);
      });
    });

    document.body.appendChild(scriptjq);*/


  }
}
