<div style="height: 56px; background: linear-gradient(232deg,#bbedc2,#99e6a9 15%,#73c59c 75%,#458076);;"></div>

<ng-container>
    <!-- End Search Form -->
    <div class="container job-category-section pt-4 pb-4">
        <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">

            <!-- Left side -->
            <div class="" fxFlex="70" fxFlex.lt-lg="65" fxFlex.lt-md="100">
                <!-- Breadcrumbs -->
                <div class="route-history">
                    <ul class="list-inline breadcrumbs mb-4">
                        <li class="list-inline-item">
                            <span [routerLink]="[ '/jobs' ]" routerLinkActive="active" class="beadcrums-child">Accueil</span>
                        </li>
                        <li class="list-inline-item">
                            <span class="beadcrums-child" routerLinkActive="active">Annonces enregistrées</span>
                        </li>
                        <!-- <li class="list-inline-item">
                            <span class="beadcrums-child">{{job?.jobName}}</span>
                        </li> -->
                    </ul>
                </div>
                <!-- <ul class="list-inline breadcrumbs mb-4">
                    <li class="list-inline-item">
                        <a>Mes Candidatures</a>
                    </li>
                    <li class="list-inline-item">
                        <a>Offres sauvegardées</a>
                    </li>
                </ul> -->

                <!-- Section title -->
                <div class="mb-4" fxLayout="row" fxLayoutAlign="space-between center">
                    <h3 class="mr-2" style="color: #03334F;">
                        {{jobsFavorite?.length == 0 ? '0 ' : jobsFavorite?.length}} Annonces enregistrées</h3>
                </div>

                <!-- List -->
                <div class="list">
                    <mat-card *ngFor="let jobFavorite of jobsFavorite" class="card-list-item p-0" fxLayout="row" fxLayoutAlign="start stretch">
                        <div class="card-list-image" fxFlex="30" fxFlex.xs="45">
                            <img routerLink="/job/{{jobFavorite?.id}}" src="{{ imageBaseUrl + jobFavorite?.jobImage }}" width="220px" height="100%" style="border-radius: 20px 0 0 20px; object-fit: cover;" class="" alt="icon">
                            <span class="card-overlay-logo">
                                <img src="{{ imageBaseUrl + jobFavorite?.logo }}" width="60px" class="" alt="icon">
                            </span>
                        </div>
                        <mat-card-content fxFlex="70" fxFlex.xs="55" class="card-list-body" fxLayout="row wrap" fxLayoutAlign="space-between center">
                            <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                <div class="media w-100">
                                    <div class="media-left" fxHide.lt-md>
                                        <mat-icon matSuffix svgIcon="category_nettoyage"></mat-icon>
                                    </div>
                                    <div class="media-body">
                                        <mat-card-title routerLink="/job/{{jobFavorite.id}}">{{ jobFavorite?.jobName }}
                                        </mat-card-title>
                                        <mat-card-subtitle>{{ jobFavorite?.jobType }} - {{ jobFavorite?.jobAddress }}
                                        </mat-card-subtitle>
                                        <p class="card-body-text" fxHide.lt-md [innerHTML]="jobFavorite.mission"></p>
                                    </div>
                                    <div class="media-right">
                                        <p class="item-price">{{ jobFavorite?.salary === '0.00' ? 'N/A' : jobFavorite?.salary}}
                                            <br>
                                            <span *ngIf="jobFavorite?.salary !== '0.00'">{{jobFavorite?.currency}}<!--/mois--></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100 card-list-footer" fxLayout="row wrap" fxLayoutAlign="space-between center">
                                <!--<button mat-button color="primary" class="btn-contacter" fxHide.lt-md>
                                    <mat-icon matSuffix svgIcon="icon_contact_white"></mat-icon>
                                    <span>Contacter l'employeur</span>
                                </button>-->
                                <button mat-mini-fab color="secondary" class="btn-headphone-fab" fxShow.lt-md (click)="playAudio(jobFavorite.jobName, jobFavorite.audio, jobFavorite)">
                                    <mat-icon svgIcon="headphone_audio"></mat-icon>
                                </button>
                                <!-- <span style="color: #458076;"><span style="font-weight: 500;">Postulé le:</span>
                                    {{jobFavorite?.applicationDate |
                                    date:'dd-MM-yyyy'}}</span> -->
                                <div class="" fxLayout="row wrap">
                                    <a class="icon-link mr-2">
                                        <!-- <mat-icon>share</mat-icon> -->
                                    </a>
                                    <a class="icon-link">
                                        <mat-icon class="icon-favorite">favorite</mat-icon>
                                    </a>
                                </div>
                            </div>
                            <!-- <div class="w-100 card-list-footer" fxLayout="row wrap" fxLayoutAlign="end end">
                                <div class="" fxLayout="row wrap" *ngIf="jobFavorite.applicationDate != null">
                                    <span style="color: #458076;"><span style="font-weight: 500;">Postulé le:</span>
                                        {{jobFavorite.applicationDate}}</span>
                                </div>
                            </div> -->
                            <button mat-mini-fab color="secondary" class="btn-headphone-fab" fxHide.lt-md (click)="playAudio(jobFavorite.jobName, jobFavorite.audio, jobFavorite)">
                                <mat-icon svgIcon="headphone_audio"></mat-icon>
                            </button>
                        </mat-card-content>
                    </mat-card>
                </div>
                <!-- End List -->

            </div>
            <!-- End Left side -->

            <!-- Right side -->
            <div class="right-sidebar" fxFlex="30" fxFlex.lt-lg="35" fxFlex.lt-md="100">
                <app-newsletter></app-newsletter>
            </div>
            <!-- End Right side -->
        </div>
    </div>
</ng-container>
