<ng-form [formGroup]="registerForm" class="login-form"  autocomplete="off" >

    <div class="main_row">

        <div class="left_side_container left-content"
        [ngClass]="{'entreprise-image': f.role_id.value, 'left-content': !f.role_id.value}">
    </div>

    <div class="right_side_container rigth-content">

        <div class="right_top_side_header">
            <div class="back-to">
                <a routerLink="/jobs" mat-button>
                    <mat-icon style="color: green">keyboard_backspace</mat-icon>
                    <span> Accueil </span>
                </a>
            </div>
            <div>
                <mat-icon svgIcon="gotaf_color" class="login-logo"></mat-icon>
                <p class="title">Votre aventure démarre ici</p>
            </div>
        </div>

        <div class="right_side_center">
            <!-- <div fxFlex="30" fxFlex.xs="25" fxFlex.sm="25" fxLayout="column" fxLayoutAlign="flex-end"
                *ngIf="isFirst && !isMobileDevice" style="padding-top: 20px;" >
                <p class="title">M'inscrire avec</p>
                <div fxLayout="row">
                    <button (click)="signWithGmail()" fxFlex="50" class="default-button-style google" mat-button>
                        <mat-icon class="social-connect" svgIcon="google"></mat-icon>
                    </button>
                    <button (click)="signWithFacebook()" fxFlex="50" class="default-button-style facebook"
                    mat-button>
                    <mat-icon class="social-connect" svgIcon="facebook"></mat-icon>
                </button>
            </div>
            <div fxLayout="row" *ngIf="isIOSDevice">
                <button (click)="signWithApple()" fxFlex="100" class="default-button-style">
                    sign in with apple
                </button>
            </div>
        </div>-->

        <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="11" *ngIf="isFirst"> -->


            <!-- <mat-radio-group aria-label="Select an option" fxLayout="row" fxLayoutAlign="space-between center"
                fxFlex="11" *ngIf="isFirst" formControlName="role_id">
                <mat-radio-button color="primary" fxFlex="70" [ngClass]="{'entreprise-button-color': f.role_id.value}"
                [value]="false">
                <div style="display: flex">
                    <div>
                        <mat-icon>person_outline</mat-icon>
                    </div>

                    <div>
                        <span style="margin-left: 10px;">Je suis un candidat</span>
                    </div>
                </div>
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="true" fxFlex="30"
            [ngClass]="{'default-button-color': !f.role_id.value}" fxLayoutAlign="center end">
            <mat-icon svgIcon="icon_company"></mat-icon>
            <span style="margin-left: 10px">Je suis une entreprise</span>
        </mat-radio-button>
    </mat-radio-group> -->
    <!--
        <button fxFlex="50" [ngClass]="{'entreprise-button-color': isEntreprise}"
        class="default-button-style button-type" mat-stroked-button (click)="setStateUserType(false)">
        <span class="button-text">Je suis un candidat</span>
    </button>
    <button fxFlex="50" [ngClass]="{'default-button-color': !isEntreprise}"
    class="default-button-style button-type" mat-stroked-button (click)="setStateUserType(true)">
    <span class="button-text">je suis une entreprise</span>
</button> -->
<!-- </div> -->
<!-- <div fxFlex="10" fxLayout="row" fxLayoutAlign="center center" *ngIf="!isMobileDevice">
    <p class="title">OU</p>
</div> -->
<div fxLayout="column" fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutAlign="center center">
        <button fxFlex="50" (click)="setStateUserType(false)"
        [ngClass]="{'default-button-color select': !isEntreprise}"
        class="default-button-style button-type" mat-stroked-button>
        <span class="button-text">Je suis un candidat</span>
    </button>

    <button fxFlex="50" (click)="setStateUserType(true)"
    [ngClass]="{'entreprise-button-color select': isEntreprise}"
    class="default-button-style button-type" mat-stroked-button>
    <span class="button-text">Je suis une entreprise</span>
</button>
</div>
<div style="margin-top: 20px;">
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" matInput placeholder="Utilisateur" formControlName="name">
        <mat-icon matPreffix class="icon-class">person</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;" *ngIf="isEntreprise">
        <input class="login-input" matInput placeholder="Raison social"
        formControlName="companyName">
        <mat-icon matPreffix class="icon-class">account_balance</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;" *ngIf="isEntreprise">
        <input class="login-input" matInput placeholder="Siret" formControlName="companySiret">
        <mat-icon matPreffix class="icon-class">create</mat-icon>
    </mat-form-field>
    <!-- <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" matInput placeholder="prénom" formControlName="firstName">
    </mat-form-field> -->
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input login_country" matInput placeholder="Téléphone" formControlName="userPhone" autocomplete="off" >
        <mat-icon matPreffix class="icon-class icon_country">call</mat-icon>
        <mat-select [(ngModel)]="countryCodeSelected" formControlName="country"  [ngClass]="countryCodeSelected" id="select_country" >
            <mat-option value="option1">+1
                <img with="10" height="10" src="assets/image/flag/us.png">
            </mat-option>
            <mat-option value="option2">+33
                <img with="10" height="10" src="assets/image/flag/fr.png">
            </mat-option>
            <mat-option value="option3">+221
                <img with="10" height="10" src="assets/image/flag/sn.png">
            </mat-option>
            <mat-option value="option4">+229
              <img with="10" height="10" src="assets/image/flag/bj.png">
            </mat-option>
            <mat-option value="option5">+228
              <img with="10" height="10" src="assets/image/flag/tg.png">
            </mat-option>
            <mat-option value="option6">+225
              <img with="10" height="10" src="assets/image/flag/ci.png">
            </mat-option>
        </mat-select>
        <mat-error *ngIf="registerForm.get('userPhone').hasError('pattern')">
            Numéro de téléphone invalide
        </mat-error>
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" type="password" matInput placeholder="Mot de passe"
        formControlName="password">
        <mat-icon matPreffix class="icon-class">vpn_key</mat-icon>
    </mat-form-field>
</div>
<!-- <div class="tab" *ngIf="!isFirst">
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" matInput placeholder="Username" formControlName="userName">
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" matInput placeholder="Email" formControlName="email">
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" type="password" matInput placeholder="Mot de passe"
        formControlName="password">
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" type="password" matInput placeholder="Confirmer mot de passe"
        formControlName="confirmPassword">
    </mat-form-field>
</div> -->
</div>

</div>



<div class="right_side_bottom">
    <div fxLayoutAlign="space-between center">
        <div></div>
        <!-- <button *ngIf="!isFirst" class="extended-fab-button" mat-fab style="background-color: white;"
            (click)="previous()">
            <mat-icon style="color: #cecfd3;">arrow_back</mat-icon>
            <span class="extended-fab-button__text">Précédent</span>
        </button> -->
        <button class="extended-fab-button" mat-fab style="background-color: white;" (click)="onSubmit()">
            <span class="extended-fab-button__text">{{buttonText}}</span>
            <!-- <mat-icon style="color: #cecfd3;" *ngIf="isFirst">arrow_forward</mat-icon> -->
        </button>
    </div>
</div>

</div>
</div>

</ng-form>

<!--audio player-->
<div class="playerDiv" *ngIf="showPlayer">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="closePlayer()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <app-audio-player></app-audio-player>
</div>
<!--end audio player-->
<mat-icon class="audio-icon" svgIcon="audio-yellow" (click)="playAudio()"></mat-icon>
