import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client/http-client.service';
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { Company } from '../../model/company';
import { JobApplied } from '../../model/jobApplied';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private apiUrl: any;

  constructor(
    private http: HttpClientService
  ) {
    this.apiUrl = environment.backendApiUrl;
  }

  /**
   * get company details
   */
  public getCompanyByUser(userId: number): Observable<Company> {
    return this.http.get<Company>(this.apiUrl + 'company/user/' + userId)
      .pipe(map(company => {
        return company['company'];
      }));
  }
  /**
   * get company details
   */
  public getCompany(companyId: number): Observable<Company> {
    return this.http.get<Company>(this.apiUrl + 'company/' + companyId)
      .pipe(map(company => {
        return company['company'];
      }));
  }

  /**
   * update company details
   */
  public updateCompany(data, companyId: number): Observable<Company> {
    return this.http.post<any>(this.apiUrl + 'company/' + companyId + '/update', data)
      .pipe(map(company => {
        return company['company'];
      }));
  }

  public updateSonorizeUserCompany(data, userId: number): Observable<Company> {
    return this.http.post<any>(this.apiUrl + 'sonorize/updateusercompanyinfos/'+userId, data)
      .pipe(map(result => {
        return result;
      }));
  }

  /**
   * get company all jobs
   */
  public getCompanyJobs(companyId: number, options = {page: 1}): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + 'company/' + companyId + '/jobs?page=' + options.page)
      .pipe(map(companyJobs => {
        return companyJobs;
      }));
  }

    /**
   * get company audio reviews
   */
    public getCompanyReviews(companyId: number): Observable<any[]> {
      return this.http.get<any>(this.apiUrl + 'sonorize/get-reviews/' + companyId)
        .pipe(map(companyReviews => {
          return companyReviews;
        }));
    }

  /**
   * get company applied jobs
   */
  public getCompanyAppliedJobs(companyId: number): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + 'company/' + companyId + '/jobs/applied')
      .pipe(map(companyJobs => {
        return companyJobs;
      }));
  }

  /**
   * get company job applied users
   */
  public getCompanyJobAppliedUsers(jobId: number): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + 'company/job/applied/' + jobId)
      .pipe(map(jobAppliedUsers => {
        return jobAppliedUsers['jobAppliedUsers'];
      }));
  }

  /**
   * get all subscription plans
   */
  public getSubscriptionPlans(): Observable<any[]> {
    return this.http.get<any>(this.apiUrl + 'subscriptionPlan')
      .pipe(map(subscriptionPlans => {
        return subscriptionPlans['subscriptionPlans'];
      }));
  }

  public markAsOrderedJob(companyId, jobId): Observable<any[]> {
    return this.http.post<any>(this.apiUrl + `company/${companyId}/jobs/${jobId}/update`, {
      orderedAudio: 1
    });
  }

  public chooseUserForJob(companyId, {jobId, userId}): Observable<any[]> {
    return this.http.post<any>(this.apiUrl + `company/${companyId}/jobs/${jobId}/choose_user/${userId}`, {
      chooseUser: true
    });
  }

  conpanySubscribe(companyId: number, data: any) {
    return this.http.post<any>(this.apiUrl + `company/${companyId}/subscribe`, data)
      .pipe(map(result => {
        return result;
      }));
  }
}
