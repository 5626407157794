<div style="height: 56px; background: linear-gradient(232deg,#bbedc2,#99e6a9 15%,#73c59c 75%,#458076);;"></div>

<ng-container>
  <div class="container job-category-section pt-4 pb-4">
    <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch">

      <!-- Left side -->
      <div class="" fxFlex="70" fxFlex.lt-lg="65" fxFlex.lt-md="100">
        <!-- Breadcrumbs -->
        <div class="route-history">
          <ul class="list-inline breadcrumbs mb-4">
            <li class="list-inline-item">
              <span [routerLink]="[ '/jobs' ]" routerLinkActive="active" class="beadcrums-child">Accueil</span>
            </li>
            <li class="list-inline-item">
              <span class="beadcrums-child" routerLinkActive="active">offre créée</span>
            </li>
            <!-- <li class="list-inline-item">
                                            <span class="beadcrums-child">{{job?.jobName}}</span>
                                        </li> -->
          </ul>
        </div>
        <!-- <ul class="list-inline breadcrumbs mb-4">
                    <li class="list-inline-item">
                        <a>Mes Candidatures</a>
                    </li>
                    <li class="list-inline-item">
                        <a>Toutes les offres postulées</a>
                    </li>
                </ul> -->

        <!-- Section title -->
        <div class="mb-4" fxLayout="row" fxLayoutAlign="space-between center">
          <h3 class="mr-2" style="color: #03334F;">
            {{jobsCreate?.length == 0 ? '0' : jobsCreate?.length}} offres Créée(s)</h3>
        </div>

        <!-- List -->
        <div infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="600" (scrolled)="onScroll()">
          <div class="list">
            <mat-card *ngFor="let jobApply of jobsCreate" class="card-list-item p-0" fxLayout="row"
              fxLayoutAlign="start stretch">
              <div class="card-list-image" fxFlex="30" fxFlex.xs="45">
                <img src="{{ imageBaseUrl + jobApply.jobImage }}" width="220px" height="100%"
                  style="border-radius: 20px 0 0 20px; object-fit: cover;" class="" alt="icon">
                <span class="card-overlay-logo">
                  <img src="{{ imageBaseUrl + jobApply.logo }}" width="60px" class="" alt="icon">
                </span>
              </div>
              <mat-card-content fxFlex="70" fxFlex.xs="55" class="card-list-body" fxLayout="row wrap"
                fxLayoutAlign="space-between center">
                <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <div class="media w-100">
                    <div class="media-left" fxHide.lt-md>
                      <mat-icon matSuffix svgIcon="{{ jobApply.jobCategoryImage }}"></mat-icon>
                    </div>
                    <div class="media-body">
                      <mat-card-title routerLink="/job/{{jobApply.id}}">{{ jobApply.jobName }}
                      </mat-card-title>
                      <mat-card-subtitle>{{ jobApply.jobType }} - {{ jobApply.jobAddress }}
                      </mat-card-subtitle>
                      <p class="card-body-text" fxHide.lt-md [innerHTML]="jobApply.mission"></p>
                    </div>
                    <div class="media-right">
                      <p class="item-price">{{ jobApply.salary === '0.00' ? 'N/A' :
                        jobApply.salary}}<br>
                        <span *ngIf="jobApply.salary !== '0.00'">{{jobApply.currency}}<!--/mois--></span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-100 card-list-footer" fxLayout="row wrap" fxLayoutAlign="space-between center">
                  <button *ngIf="jobApply.active === 1" mat-button color="primary" class="btn-contacter" fxHide.lt-md>
                    <span> Active </span>
                  </button>
                  <button *ngIf="jobApply.active === 0" mat-button color="primary" class="btn-contacter" fxHide.lt-md
                    disabled>
                    <span> Inactive </span>
                  </button>
                  <button mat-mini-fab color="secondary" class="btn-headphone-fab" fxShow.lt-md
                    (click)="playAudio(jobApply.jobName, jobApply.audio, jobApply)">
                    <mat-icon svgIcon="headphone_audio"></mat-icon>
                  </button>
                  <span style="color: #458076;"><span style="font-weight: 500;">Publié le:</span>
                    {{jobApply.createdAt |
                    date:'dd-MM-yyyy'}}</span>
                </div>
                <!-- <div class="w-100 card-list-footer" fxLayout="row wrap" fxLayoutAlign="end end">
                                  <div class="" fxLayout="row wrap">
                                      <span style="color: #458076;"><span style="font-weight: 500;">Postulé le:</span>
                                          {{jobApply.applicationDate | date:'dd-MM-yyyy'}}</span>
                                  </div>
                              </div> -->

                <button mat-mini-fab color="secondary" class="btn-headphone-fab" fxHide.lt-md
                  (click)="playAudio(jobApply.jobName, jobApply.audio, jobApply)">
                  <mat-icon svgIcon="headphone_audio"></mat-icon>
                </button>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <!-- End List -->

      </div>
      <!-- End Left side -->

      <!-- Right side -->
      <div class="right-sidebar" fxFlex="30" fxFlex.lt-lg="35" fxFlex.lt-md="100">
        <app-newsletter></app-newsletter>
      </div>
      <!-- End Right side -->
    </div>
  </div>
</ng-container>
