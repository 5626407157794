import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackService } from '../../../service/snack/snack.service';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { AudioService } from '../../../service/audio.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmCodeComponent } from '../confirm-code/confirm-code.component';
import { Platform } from '@angular/cdk/platform';
// import { SignInWithApple } from 'cordova-plugin-apple-login'
declare var FCMPlugin: any;

function _window(): any {
  return window;
}
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  public registerForm: FormGroup;
  public isFirst = true;
  public submitted = false;
  private audioUrl = 'assets/audio/inscription-user.wav';
  public showPlayer = false;
  public buttonText = 'Valider';
  public isEntreprise = false;
  public isIOSDevice = false;
  public isMobileDevice = false;
  public deviceToken = "";
  public deviceType :any;
  public countryCodeSelected = "option2";
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snack: SnackService,
    private socialService: SocialAuthService,
    private audioService: AudioService,
    private matDialog: MatDialog,
    private plt: Platform
  ) {}


  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      role_id: [false, Validators.required],
      name: [''],
      companySiret: [''],
      password: ['', Validators.required],
      country: ['', Validators.required],
      // userPhone: ['', Validators.compose([Validators.required, Validators.pattern('^((\\+)33|0)[1-9](\\d{2}){4}$')])],
      userPhone: ['', Validators.required],
      // lastName: ['', Validators.required],
      companyName: [''],
      deviceToken: [''],
      // deviceToken: ['',Validators.required],
      deviceType: [''],
      // deviceType: ['',Validators.required],
    });
    this.registerForm.controls.role_id.valueChanges.subscribe((data) => {
      console.log(data);
      if (data === false) {
        this.audioUrl = 'assets/audio/inscription-user.wav';
      } else {
        this.audioUrl = 'assets/audio/inscription-company.wav';
      }
    });

  }

  get f(): { [p: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  public setStateUserType(type: boolean): void {
    this.isEntreprise = type;
    this.registerForm.controls.role_id.setValue(type);
  }

  public onSubmit(): void {
    this.submitted = true;

    if (this.registerForm.invalid || ((this.registerForm.value.role_id === true && this.registerForm.controls.companySiret.value === '')
    || (this.registerForm.value.role_id === true && this.registerForm.controls.companyName.value === ''))) {
      return;
    }

    if (this.registerForm.value.role_id === true) {
      this.registerForm.controls.role_id.setValue('3');
    } else {
      this.registerForm.controls.role_id.setValue('2');
    }

    if(this.registerForm.value.country == "option1"){
      this.registerForm.controls.country.setValue('1');
    }
    else if(this.registerForm.value.country == "option2"){
      this.registerForm.controls.country.setValue('33');
    }
    else if(this.registerForm.value.country == "option3"){
      this.registerForm.controls.country.setValue('221');
    }
    else if(this.registerForm.value.country == "option4"){
      this.registerForm.controls.country.setValue('229');
    }
    else if(this.registerForm.value.country == "option5"){
      this.registerForm.controls.country.setValue('228');
    }
    else if(this.registerForm.value.country == "option6"){
      this.registerForm.controls.country.setValue('225');
    }
    this.registerForm.value.userPhone.replace(/ /g, '');
    this.registerForm.controls.deviceToken.setValue(this.deviceToken);
    this.registerForm.controls.deviceType.setValue(this.deviceType);
    if (this.registerForm.value.role_id === '3') {
      this.authenticationService.register(this.registerForm.value)
        .pipe(first())
        .subscribe({
          next: () => {
            // get return url from query parameters or default to home page
            if (this.registerForm.value.role_id === '2') {
              const returnUrl = this.route.snapshot.queryParams.returnUrl || '/jobs';
              this.router.navigateByUrl(returnUrl).then(r => '');
            } else {
              const returnUrl = this.route.snapshot.queryParams.returnUrl || '/company-job-applied';
              this.router.navigateByUrl(returnUrl);
            }
            this.snack.info('Bienvenue sur gotaf!'); // TODO: add user firstname on message
          },
          error: (error) => {
            this.snack.error('Le numéro de téléphone n est pas valide');
          }
        });
    } else {
      this.authenticationService.validateUserPhone({ phone: this.registerForm.value.userPhone,country: this.registerForm.value.country}).subscribe(() => {
        this.matDialog.open(ConfirmCodeComponent, { width: '500px', height: '300px', data: this.registerForm.value });
        this.snack.valid('Merci de patienter, vous aller recevoir un code par SMS. Rentrer le code dans la zone indiquée, puis valider');
      }, (e) => {
        this.snack.error('Le numéro de téléphone n est pas valide');
      });
    }
  }

  public async signWithGmail(): Promise<void> {

    // this.socialService.initState.subscribe((data) => {
    //   console.log(data);
    // }, console.error, () => {
    //   this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID);
    //   console.log('all providers are ready');
    // });
    new Promise(() => {
      this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
        this.registerForm.addControl('firstName', new FormControl(''));
        this.registerForm.addControl('lastName', new FormControl(''));
        this.registerForm.addControl('email', new FormControl(''));
        this.registerForm.addControl('image', new FormControl(''));
        this.registerForm.controls.name.setValue(userData.name);
        this.registerForm.controls.firstName.setValue(userData.firstName);
        this.registerForm.controls.lastName.setValue(userData.lastName);
        this.registerForm.controls.image.setValue(userData.photoUrl);
        this.registerForm.controls.email.setValue(userData.email);
      });
    });
  }

  public async signWithFacebook(): Promise<void> {
    // let result: any;
    // try {
    //   result = await this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    //   console.log(result);
    // } catch (error) {
    //   console.log(error);
    // }
    // tslint:disable-next-line: no-unused-expression
    new Promise(() => {
      this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
        this.registerForm.addControl('firstName', new FormControl(''));
        this.registerForm.addControl('lastName', new FormControl(''));
        this.registerForm.addControl('email', new FormControl(''));
        this.registerForm.addControl('image', new FormControl(''));
        this.registerForm.controls.name.setValue(userData.name);
        this.registerForm.controls.firstName.setValue(userData.firstName);
        this.registerForm.controls.lastName.setValue(userData.lastName);
        this.registerForm.controls.image.setValue(userData.photoUrl);
        this.registerForm.controls.email.setValue(userData.email);
      });
    });

  }
  public async signWithApple(): Promise<void> {
    _window().cordova.plugins.SignInWithApple.signin(
      { requestedScopes: [0, 1] },
      function (succ) {
        this.registerForm.addControl('firstName', new FormControl(''));
        this.registerForm.addControl('lastName', new FormControl(''));
        this.registerForm.addControl('email', new FormControl(''));
        this.registerForm.addControl('image', new FormControl(''));
        this.registerForm.controls.name.setValue(succ.name);
        this.registerForm.controls.firstName.setValue(succ.fullName.familyName);
        this.registerForm.controls.lastName.setValue(succ.fullName.givenName);
        this.registerForm.controls.image.setValue('');
        this.registerForm.controls.email.setValue(succ.email);
        console.log(succ);
        // alert(JSON.stringify(succ))
      },
      function (err) {
        console.error(err);
        console.log(JSON.stringify(err));
      }
    );
    // SignInWithApple.isAvailable().then(function (isAvailable) {
    //   console.info(isAvailable)
    // })

  }

  public playAudio(): void {
    this.showPlayer = true;
    this.audioService.playStream(this.audioUrl).subscribe(events => {
    });
  }

  public playStream(url): void {
    this.audioService.playStream(url).subscribe(events => {
    });
  }

  public closePlayer(): void {
    this.audioService.stop();
    this.showPlayer = false;
  }
}

