<!DOCTYPE html>
<html lang="en">

    <head>
        <meta charset="utf-8" />
        <title>Sonorize - Admin & Dashboard</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        <meta content="Sonorize - Admin & Dashboard" name="description" />
        <meta content="" name="author" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />

        <!-- App favicon -->
        <link rel="shortcut icon" href="../../../../assets/sonorize-template/assets/images/favicon.ico">

        <!--<link href="../../../../assets/sonorize-template/plugins/jvectormap/jquery-jvectormap-2.0.2.css" rel="stylesheet">
        <link href="../../../../assets/sonorize-template/plugins/lightpick/lightpick.css" rel="stylesheet" />


        <link href="../../../../assets/sonorize-template/assets/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
        <link href="../../../../assets/sonorize-template/assets/css/jquery-ui.min.css" rel="stylesheet">
        <link href="../../../../assets/sonorize-template/assets/css/icons.min.css" rel="stylesheet" type="text/css" />
        <link href="../../../../assets/sonorize-template/assets/css/metisMenu.min.css" rel="stylesheet" type="text/css" />
        <link href="../../../../assets/sonorize-template/assets/css/app.min.css" rel="stylesheet" type="text/css" />-->

        <!-- jQuery  -->
        <script src="../../../../assets/sonorize-template/assets/js/jquery.min.js"></script>
        <script src="../../../../assets/sonorize-template/assets/js/bootstrap.bundle.min.js"></script>
        <script src="../../../../assets/sonorize-template/assets/js/metismenu.min.js"></script>
        <script src="../../../../assets/sonorize-template/assets/js/waves.js"></script>
        <script src="../../../../assets/sonorize-template/assets/js/feather.min.js"></script>
        <script src="../../../../assets/sonorize-template/assets/js/jquery.slimscroll.min.js"></script>
        <script src="../../../../assets/sonorize-template/assets/js/jquery-ui.min.js"></script>

        <script src="../../../../assets/sonorize-template/plugins/moment/moment.js"></script>
        <script src="../../../../assets/sonorize-template/plugins/apexcharts/apexcharts.min.js"></script>
        <script src="../../../../assets/sonorize-template/plugins/jvectormap/jquery-jvectormap-2.0.2.min.js"></script>
        <script src="../../../../assets/sonorize-template/plugins/jvectormap/jquery-jvectormap-world-mill-en.js"></script>
        <script src="../../../../assets/sonorize-template/plugins/chartjs/chart.min.js"></script>
        <script src="../../../../assets/sonorize-template/plugins/chartjs/roundedBar.min.js"></script>
        <script src="../../../../assets/sonorize-template/plugins/lightpick/lightpick.js"></script>
        <script src="../../../../assets/sonorize-template/assets/pages/jquery.sales_dashboard.init.js"></script>


        <!-- App js -->
        <script src="../../../../assets/sonorize-template/assets/js/app.js"></script>
    </head>

    <body>

      <!-- Top Bar Start -->
      <div class="topbar">

         <!-- LOGO -->
         <div class="topbar-left">
             <a href="../../../../assets/sonorize-template/dashboard/crm-index.html" class="logo">
                 <span>
                     <img src="assets/image/sonorizebygotaf.jpg" alt="logo-big logo-lg" class="logo lg">
                 </span>
             </a>
         </div>
         <!--end logo-->
         <!-- Navbar -->
         <nav class="navbar-custom">
             <ul class="list-unstyled topbar-nav float-right mb-0">

                 <li class="dropdown" *ngIf="user">
                     <a class="nav-link dropdown-toggle waves-effect waves-light nav-user" data-toggle="dropdown" role="button"
                         aria-haspopup="false" aria-expanded="false">
                         <img src="{{ image }}" alt="profile-user" class="rounded-circle" />
                         <span class="ml-1 nav-user-name hidden-sm">{{ user.name }} <i class="mdi mdi-chevron-down"></i> </span>
                     </a>
                     <div class="dropdown-menu dropdown-menu-right">
                         <a class="dropdown-item" routerLink="/sonorize/parameters"><i class="ti-settings text-muted mr-2"></i> Paramètres de Compte</a>
                         <div class="dropdown-divider mb-0"></div>
                         <a class="dropdown-item" href="#" (click)="logout()"><i class="ti-power-off text-muted mr-2"></i> Déconnexion</a>
                     </div>
                 </li>
             </ul><!--end topbar-nav-->

             <ul class="list-unstyled topbar-nav mb-0">
                 <li>
                     <button onclick="$('#left-sidenav').toggle()" class="nav-link button-menu-mobile waves-effect waves-light">
                         <i class="ti-menu nav-icon"></i>
                     </button>
                 </li>
                 <li class="hide-phone app-search">
                     <form role="search" class="">
                         <input type="text" id="AllCompo" placeholder="Search..." class="form-control">
                         <a href=""><i class="fas fa-search"></i></a>
                     </form>
                 </li>
             </ul>
         </nav>
         <!-- end navbar-->
     </div>
     <!-- Top Bar End -->

     <!-- Left Sidenav -->
     <div class="left-sidenav" id="left-sidenav">
         <ul class="metismenu left-sidenav-menu">
             <li>
                 <a class="a-first-level {{currentSection == 'dashboard' ? 'active' : ''}}" href="javascript: void(0);"><i class="ti-bar-chart"></i><span>Tableau de bord</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                 <ul class="nav-second-level" aria-expanded="false">
                     <li class="nav-item"><a class="nav-link {{currentPage == 'campaigns' ? 'active' : ''}}" routerLink="/sonorize"><i class="ti-control-record"></i>Campagnes</a></li>
                     <li class="nav-item"><a class="nav-link {{currentPage == 'reviews' ? 'active' : ''}}" routerLink="/sonorize/reviews"><i class="ti-control-record"></i>Audios Widget</a></li>
                 </ul>
             </li>
             <li *ngIf="user">
                <a class="a-first-level {{currentSection == 'profile' ? 'active' : ''}}" href="javascript: void(0);"><i class="ti-user"></i><span>Profile</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                <ul class="nav-second-level" aria-expanded="false">
                    <li class="nav-item"><a class="nav-link {{currentPage == 'parameters' ? 'active' : ''}}" routerLink="/sonorize/parameters"><i class="ti-control-record"></i>Paramètres</a></li>
                </ul>
              </li>
             <li *ngIf="!user">
                 <a href="javascript: void(0);"><i class="ti-lock"></i><span>Authentification</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                 <ul class="nav-second-level" aria-expanded="false">
                     <li class="nav-item"><a class="nav-link" routerLink="/sonorize/login"><i class="ti-control-record"></i>Connexion</a></li>
                     <li class="nav-item"><a class="nav-link" routerLink="/register-sonorize"><i class="ti-control-record"></i>Inscription</a></li>
                     <!--<li class="nav-item"><a class="nav-link" href="../../../../assets/sonorize-template/authentication/auth-recover-pw.html"><i class="ti-control-record"></i>Recover Password</a></li>-->
                 </ul>
             </li>
         </ul>
     </div>
    <!-- end left-sidenav-->
    <div class="page-wrapper">

      <!-- Page Content-->
      <div class="page-content">

        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    </body>
