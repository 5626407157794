import { UserProfileComponent } from './module/user/user-profile/user-profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './module/homepage/homepage.component';
import { LoginComponent } from './module/user/login/login.component';
import { JobsComponent } from './module/jobs/jobs.component';
import { JobDetailComponent } from './module/jobs/job-detail/job-detail.component';
import { JobApplicationComponent } from './module/jobs/job-application/job-application.component';
import { UserCvCreateComponent } from './module/user/user-cv-create/user-cv-create.component';
import { JobAddComponent } from './module/jobs/job-add/job-add.component';
import { JobCategoryComponent } from './module/jobs/job-category/job-category.component';
import { HeaderComponent } from './module/header/header.component';
import { SonorizeHeaderComponent } from './module/header/sonorize/sonorizeheader.component';
import { AuthGuard } from './_helpers';
import { Role } from './model/role';
import { CompanyJobsComponent } from './module/company/company-jobs/company-jobs/company-jobs.component';
import { CompanyJobAppliedComponent } from './module/company/company-job-applied/company-job-applied.component';
import { CompanyUserCompareComponent } from './module/company/company-user-compare/company-user-compare.component';
import { CompanyProfileCandidateComponent } from './module/company/company-profile-candidate/company-profile-candidate.component';
import { SignupComponent } from './module/user/signup/signup.component';
import { SignupSonorizeComponent } from './module/user/signup-sonorize/signup-sonorize.component';
import { SonorizeVerbatimsComponent } from './module/sonorize/sonorize-verbatims/sonorize-verbatims.component'
import { UserJobAppliedComponent } from './module/user/user-job-applied/user-job-applied.component';
import { UserJobFavoriteComponent } from './module/user/user-job-favorite/user-job-favorite.component';
import { UserDashboardComponent } from './module/user/dashboard/dashboard.component';
import { CompanySubscriptionsComponent } from './module/company/company-subscriptions/company-subscriptions.component';
import { CompanyModifyComponent } from './module/company/company-modify/company-modify.component';
import { PresseComponent } from './module/presse/presse.component';
import { CopyrightPolicyComponent } from './module/cgu/copyright-policy/copyright-policy.component';
import { PersonalDataManagementComponent } from './module/cgu/personal-data-management/personal-data-management.component';
import { CookiesComponent } from './module/cgu/cookies/cookies.component';
import { CgvComponent } from './module/cgu/cgv/cgv.component';
import { LoginSonorizeComponent } from './module/user/login-sonorize/login-sonorize.component';
import { SonorizeReviewsComponent } from './module/sonorize/sonorize-reviews/sonorize-reviews.component';
import { SonorizeParametersComponent } from './module/sonorize/sonorize-parameters/sonorize-parameters.component';

const routes: Routes = [

  // /**
  //  * redirect in case of non existant route
  //  */
  // {
  //   path: '**',
  //   redirectTo: '/',
  // },
  /**
   * login for user and company
   */
  {
    path: 'login',
    component: LoginComponent,
  },
  /**
   * login for sonorize company
   */
     {
      path: 'sonorize/login',
      component: LoginSonorizeComponent,
    },
  /**
   * registering a user or a company
   */
  {
    path: 'register',
    component: SignupComponent,
  },
    /**
   * registering a company for sonorize
   */
  {
    path: 'register-sonorize',
    component: SignupSonorizeComponent,
  },
  /**
   * link to apply specific job by user
   */
  {
    path: 'job-application/:job_id',
    component: JobApplicationComponent,
    // canActivate: [AuthGuard],
    data: {
      roles: [Role.User]
    }
  },
    /**
   * link to apply specific job by user (is_from_externurl)
   */
     {
      path: 'job-application/:job_id/:is_from_externurl',
      component: JobApplicationComponent,
      // canActivate: [AuthGuard],
      data: {
        roles: [Role.User]
      }
    },
  /**
   * cv creation by user
   */
  {
    path: 'user-cv-create',
    component: UserCvCreateComponent,
    // canActivate: [AuthGuard],
    data: { roles: [Role.User] }
  },
  {
      path: 'sonorize',
      component: SonorizeHeaderComponent,
      children: [
        {
          path: '',
          component: SonorizeVerbatimsComponent,
          canActivate: [AuthGuard],
          /*data: { roles: [Role.Company] }*/
        },
        {
          path: 'reviews',
          component: SonorizeReviewsComponent,
          canActivate: [AuthGuard],
          data: { roles: [Role.Company] }
        },
        {
          path: 'parameters',
          component: SonorizeParametersComponent,
          canActivate: [AuthGuard],
          data: { roles: [Role.Company] }
        },
    ],
  },
  {
    path: '',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: HomepageComponent
      },
      /**
       * page to display/modify user cv page
       */
      {
        path: 'user-profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.User] }
      },

      /**
       * page to modify user parameter informations
       */
      {
        path: 'user-parameter',
        component: UserDashboardComponent,
        canActivate: [AuthGuard]
      },
      /**
       * page to display all available jobs
       */
      {
        path: 'jobs', // retrieve all jobs existing and by search parameter
        component: JobsComponent,
      },
      /**
       * adding job only by company
       */
      {
        path: 'job-add',
        component: JobAddComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Company] }
      },
      /**
       * homepage for sonorize (managing verbatims)
       */
      /*{
        path: 'sonorize',
        component: SonorizeVerbatimsComponent,
        canActivate: [AuthGuard],
        //data: { roles: [Role.Company] }
      },*/
      /**
       * list all company jobs
       */
      {
        path: 'company-jobs',
        component: CompanyJobsComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Company] }
      },
      /**
       * all appliance for company jobs
       */
      {
        path: 'company-job-applied',
        component: CompanyJobAppliedComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Company] }
      },
      /**
       * page to modify company informations
       */
      {
        path: 'company-modify',
        component: CompanyModifyComponent,
      },
      /**
       * specific job detail displaying or editing if company logged
       */
      {
        path: 'job/:job_id',
        component: JobDetailComponent,
      },
      /**
       * list of all jobs applied by user
       */
      {
        path: 'job-applied',
        component: UserJobAppliedComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.User] }
      },
      /**
       * all jobs added to favorite by user
       */
      {
        path: 'job-favorite',
        component: UserJobFavoriteComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.User] }
      },
      /**
       * page to display job by category
       */
      {
        path: 'job-category/:job_category_id',
        component: JobCategoryComponent,
      },
      /**
       * user compare for company
       */
      {
        path: 'company-user-compare/:jobId',
        component: CompanyUserCompareComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Company] }
      },
      /**
       * user profile for company view
       */
      {
        path: 'company-profile-candidate/:userId',
        component: CompanyProfileCandidateComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Company] }
      },
      /**
       * company subscription plan
       */
      {
        path: 'subscription-plan/:compagnyId',
        component: CompanySubscriptionsComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.Company] }
      },
      /**
       * presse page
       */
      {
        path: 'presse',
        component: PresseComponent,
      },
      /**
       * copyright policy page
       */
      {
        path: 'mentions-legales',
        component: CopyrightPolicyComponent,
      },
      /**
       * personal data management page
       */
      {
        path: 'confidentialite',
        component: PersonalDataManagementComponent,
      },
      /**
       * cookies management page
       */
      {
        path: 'cookies',
        component: CookiesComponent,
      },
      /**
       * cgv management page
       */
      {
        path: 'cgv',
        component: CgvComponent,
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
