<ng-form [formGroup]="registerForm" class="login-form"  autocomplete="off" >

    <div class="main_row">

        <div class="left_side_container left-content"
        [ngClass]="{'entreprise-image': f.role_id.value, 'left-content': !f.role_id.value}">
    </div>

    <div class="right_side_container rigth-content">

        <div class="right_top_side_header">
            <div class="back-to">
                <a routerLink="/" mat-button>
                    <mat-icon style="color: green">keyboard_backspace</mat-icon>
                    <span> Portail Gotaf </span>
                </a>
            </div>
            <div>
                <mat-icon svgIcon="sonorizebygotaf" class="login-logo"></mat-icon>
                <p class="title">Formulaire d'Inscription pour le Widget Sonorize</p>
            </div>
        </div>

<div class="right_side_center">

<div *ngIf="!isSended" fxLayout="column" fxLayoutAlign="space-between">
<!--<div fxLayout="row" fxLayoutAlign="center center">
        <button fxFlex="50" (click)="setStateUserType(false)"
        [ngClass]="{'default-button-color select': !isEntreprise}"
        class="default-button-style button-type" mat-stroked-button>
        <span class="button-text">Je suis un candidat</span>
    </button>

    <button fxFlex="50" (click)="setStateUserType(true)"
    [ngClass]="{'entreprise-button-color select': isEntreprise}"
    class="default-button-style button-type" mat-stroked-button>
    <span class="button-text">Je suis une entreprise</span>
</button>
</div>-->
<div style="margin-top: 20px;">
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" matInput placeholder="Utilisateur" formControlName="name">
        <mat-icon matPreffix class="icon-class">person</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" matInput placeholder="Raison sociale"
        formControlName="companyName">
        <mat-icon matPreffix class="icon-class">create</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
      <input class="login-input" matInput placeholder="Email"
      formControlName="email">
      <mat-icon matPreffix class="icon-class">create</mat-icon>
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
      <input class="login-input" matInput placeholder="La PunchLine pour votre widget"
      formControlName="punchLine">
      <mat-icon matPreffix class="icon-class">create</mat-icon>
    </mat-form-field>
    <mat-form-field  style="width:100%" class="form-field">
    <!--<label class="text-label">Couleur dominante pour votre page widget &nbsp;</label>-->
    <input matInput type="color" (change)="setColor($event)" [ngClass]="{'no-color': !selectedColor}" (ngModelChange)="selectedColor=$event" required placeholder="Couleur dominante pour votre page widget">
    <br/><br/><br/>
    <table style="width:100%">
      <tr>
        <td>Faire apparaître les avis audio des clients sur votre widget </td>
        <td style="text-align:right">
          <input type="checkbox"
          class="checkbox-form"
          (change)="setActive($event)"/>
        </td>
      </tr>
    </table>
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
        <mat-toolbar>
          <!-- Readonly Input to show File names -->
          <input matInput readonly name="logoTxt" value="{{logoName}}" />
          <!-- Browse Button -->
          <button type="button" style="min-width:150px;" mat-raised-button (click)="UploadFileInput.click()">
            Choisir un Logo
          </button>
        </mat-toolbar>
        <!-- Fetch selected filed on change -->
        <input hidden type="file" formControlName="logo" #UploadFileInput id="logo" (change)="logoChangeEvent($event)" name="logo"
          multiple="multiple" accept="image/*" />
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
      <mat-toolbar>
        <!-- Readonly Input to show File names -->
        <input matInput readonly name="bgTxt" value="{{backgroundName}}" />
        <!-- Browse Button -->
        <button type="button" style="min-width:150px;" mat-raised-button (click)="UploadFileInput2.click()">
          Choisir un Fond
        </button>
      </mat-toolbar>
      <!-- Fetch selected filed on change -->
      <input hidden type="file" formControlName="background" #UploadFileInput2 id="logo" (change)="backgroundChangeEvent($event)" name="background"
        multiple="multiple" accept="image/*" />
    </mat-form-field>
    <mat-form-field appearance="none" style="width: 100%;">
        <input class="login-input" type="password" matInput placeholder="Mot de passe"
        formControlName="password">
        <mat-icon matPreffix class="icon-class">vpn_key</mat-icon>
    </mat-form-field>
</div>
</div>

<div *ngIf="isSended" fxLayout="column" fxLayoutAlign="space-between">
  <div style="margin-top: 20px;">
    <span><h4>Un mail va vous être envoyé dans quelques minutes avec les instructions pour le suivi / l'url publique à partager de votre widget à l'adresse mail que vous avez indiquée !</h4></span>
  </div>
</div>

</div>



<div class="right_side_bottom">
    <div fxLayoutAlign="space-between center">
        <div></div>
        <!-- <button *ngIf="!isFirst" class="extended-fab-button" mat-fab style="background-color: white;"
            (click)="previous()">
            <mat-icon style="color: #cecfd3;">arrow_back</mat-icon>
            <span class="extended-fab-button__text">Précédent</span>
        </button> -->
        <button class="extended-fab-button" mat-fab style="background-color: white;" (click)="onSubmit()">
            <span class="extended-fab-button__text">{{buttonText}}</span>
            <app-loader  class="pre_loader" *ngIf="isPreLoader == true"></app-loader>
            <!-- <mat-icon style="color: #cecfd3;" *ngIf="isFirst">arrow_forward</mat-icon> -->
        </button>
    </div>
</div>

</div>
</div>

</ng-form>

<!--audio player-->
<div class="playerDiv" *ngIf="showPlayer">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true" (click)="closePlayer()">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
    <app-audio-player></app-audio-player>
</div>
<!--end audio player-->
<mat-icon class="audio-icon" svgIcon="audio-yellow" (click)="playAudio()"></mat-icon>
