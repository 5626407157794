import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as RecordRTC from 'recordrtc';
import moment from 'moment';
import { AuthenticationService } from '../authentication/authentication.service';
import { AudioService } from '../audio.service';
import { Logger } from 'tslint/lib/runner';
import { AudioFileService } from '../audiofile.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs/internal/Subscription';
function _window(): any {
  return window;
  }
  declare var FileTransfer;
  declare var FileUploadOptions;
declare var Media: {
new(
src: string,
mediaSuccess: () => void,
mediaError?: (error: MediaError) => any,
mediaStatus?: (status: number) => void): Media;
// Media statuses
MEDIA_NONE: number;
MEDIA_STARTING: number;
MEDIA_RUNNING: number;
MEDIA_PAUSED: number;
MEDIA_STOPPED: number
};
interface IosPlayOptions {
numberOfLoops?: number;
playAudioWhenScreenIsLocked?: boolean;
}
interface Media {
position: number;
duration: number;
getCurrentAmplitude(
mediaSuccess: (amplitude: number) => void,
mediaError?: (error: MediaError) => void): void;

getCurrentPosition(
mediaSuccess: (position: number) => void,
mediaError?: (error: MediaError) => void): void;
getDuration(): number;
play(iosPlayOptions?: IosPlayOptions): void;
pause(): void;

release(): void;
seekTo(position: number): void;
setVolume(volume: number): void;
startRecord(): void;
stopRecord(): void;
stop(): void;
}
interface RecordedAudioOutput {
blob: Blob;
title: string;
}

@Injectable({
providedIn: 'root'
})
export class ReccordAudioService {
private apiUrl: any;
private stream: any;
private recorder: any;
private interval: any;
private startTime: any;
private endTime: any;
private $recorded = new Subject<Blob>();
$recordingTime = new Subject<string>();
private $recordingFailed = new Subject<string>();
public mediaRec;
public iosMediaSrc;
public recordedFileURL;
public isAudioUrl = false;
recordAudioComponentStopRecording = new EventEmitter();
subsVar: Subscription;

constructor(private authenticationService: AuthenticationService, private audioService: AudioService) {
  this.apiUrl = environment.backendApiUrl;
}

getReccordedBlob(): Observable<Blob> {
  return this.$recorded.asObservable();
}

getReccordedTime(): Observable<string> {
  return this.$recordingTime.asObservable();
}

recordingFailed(): Observable<string> {
  return this.$recordingFailed.asObservable();
}

starReccording(): void {
  if (this.recorder) {
    return;
  }

  this.$recordingTime.next('01:30');
  navigator.mediaDevices.getUserMedia({ audio: true }).then((s) => {
    this.stream = s;
    this.record();
  }).catch((e) => {
    this.$recordingFailed.next();
  });
}

abbortReccording(): void {
  this.stopMedia();
}

private record(): void {
  this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
    type: 'audio',
    mimeType: 'audio/webm'
  });
  this.recorder.record();
  this.getRecodingTime();
}
getRecodingTime(){
  this.startTime = moment();
  this.endTime = moment().add(30, 'seconds')
  .add(1, 'minutes');

  this.interval = setInterval(() => {
    const currentTime = moment();
    const diffTime = moment.duration(this.endTime.diff(currentTime));
    const getTotalSeconds = currentTime.diff(this.startTime, "seconds");
    if (getTotalSeconds > 90) {
      this.recordAudioComponentStopRecording.emit();
      return;
    }
    const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
    this.$recordingTime.next(time);
  }, 1000);
}

private toString(value: any): string {
  let val = value;
  if (!value) {
    val = '00';
  }
  if (value < 10) {
    val = '0' + value;
  }
  return val;
}

stopReccording(): void {
  if (this.recorder) {
    this.recorder.stop((blob) => {
      console.log({blob:blob});
      const fileName = /*this.authenticationService.userValue.phone +
      this.authenticationService.userValue.firstName
      + this.authenticationService.userValue.lastName*/ Date.now() +'_'+ Math.floor(Math.random() * 100000) + '.mp3';
      const file = new File([blob], fileName, { type: 'audio' });
      this.stopMedia();
      this.$recorded.next(file);
    }, () => {
      this.stopMedia();
      this.$recordingFailed.next();
    });
  }
}

stopMedia(): void {
  if (this.recorder) {
    this.recorder = null;
    clearInterval(this.interval);
    this.startTime = null;
    if (this.stream) {
      this.stream.getAudioTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }
}
// public startCapture(): void {
//   _window().audioinput.checkMicrophonePermission((hasPermission: boolean) => {
//     if (hasPermission === true) {
//       this.mediaRec = new Media(this.iosMediaSrc,
//         function () {
//           console.log("recordAudio():Audio Success");
//           // this.audioService.playStream(this.iosMediaSrc).subscribe(events => {
//           // });
//         },
//         function (err) {
//           console.log("recordAudio():Audio Error: " + err.code);
//         });

//         this.mediaRec.startRecord();
//         this.isAudioUrl = false;
//         this.getRecodingTime();
//       }
//       else {
//         // Ask the user for permission to access the microphone
//         _window().audioinput.getMicrophonePermission((hasPermissions: boolean) => {
//           if (hasPermissions === true) {
//             console.log('User granted permission to record.');
//           } else {
//             console.warn('User denied permission to record.');
//           }
//         });
//       }
//     });
//   }
  public recordingTimeGet(): Promise<any> {
    return new Promise((resolve, reject) => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
      this.$recordingTime.next(time);
      resolve(time)
    })
  }
  public stopPhoneRecording() {
    // throw new Error('Method not implemented.');
    this.mediaRec.stopRecord();
    this.recorder = null;
    this.startTime = null;
    this.$recordingTime.next("00:00");
    this.mediaRec.play()
    this.audioService.playStream(this.iosMediaSrc).subscribe(events => {
    });
    clearInterval(this.interval);
  }
  stopCapturing(type: string = '', id: any = '') {
    if (type == 'job') {
      var uri = encodeURI(this.apiUrl + 'uploadJobaudio/' + id);
    }
    else {
      var userId = this.authenticationService.userValue.id.toString();
      var uri = encodeURI(this.apiUrl + 'uploadIphoneRecording/' + userId);
    }
    this.waitAudioRecordUpload(this.iosMediaSrc, uri).then(data => {
      // this.audioService.playStream(data).subscribe(events => {
      // });
    });
  }

  public waitAudioRecordUpload(iosMediaSrc, uri): Promise<any> {
    return new Promise((resolve, reject) => {
      var options = new FileUploadOptions();
      options.fileKey = "file";
      options.fileName = iosMediaSrc.substr(iosMediaSrc.lastIndexOf('/') + 1);
      options.mimeType = "audio/wav";

      var ft = new FileTransfer();
      var progressValue = 0;
      ft.onprogress = function (progressEvent) {
        if (progressEvent.lengthComputable) {
          progressValue = progressEvent.loaded / progressEvent.total;
        } else {
          progressValue++;
        }
      };
      ft.upload(iosMediaSrc, uri,
        function (entry) {
          resolve(entry.response);
        }, function (error) {
          reject(new Error(error));
          alert("An error has occurred: Code = " + error.code);
          console.log("upload error source " + error.source);
          console.log("upload error target " + error.target);
        }, options)
    });
  }
}
