import { Component, OnInit } from '@angular/core';
import {User} from '../../../model/user';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment.prod';
import {AuthenticationService} from '../../../service/authentication/authentication.service';
import {UserService} from '../../../service/user/user.service';
import {Router} from '@angular/router';
import {SnackService} from '../../../service/snack/snack.service';
import {formatDate} from '@angular/common';
import {Company} from '../../../model/company';
import {CompanyService} from '../../../service/company/company.service';

@Component({
  selector: 'app-company-modify',
  templateUrl: './company-modify.component.html',
  styleUrls: ['./company-modify.component.css', '../../../shared/css/user-modify-infos.style.css']
})
export class CompanyModifyComponent implements OnInit {
  public user: User;
  public company: Company;
  public companyInformation: FormGroup;
  public imageBaseUrl: string = environment.imageBaseUrl;
  companyLogo: File;
  url = '';
  userStored = JSON.parse(localStorage.getItem('user'));
  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private companyService: CompanyService,
    private router: Router,
    private snack: SnackService
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
    this.user = this.userStored;
  }

  phoneNumberPattern = '^[0-9 ]*$';
  // isFirstBlockErrorShow = false;
  // isSecondBlockErrorShow = false;
  ngOnInit(): void {
    this.companyService.getCompanyByUser(this.user.id).subscribe(dataCompany => {
      this.company = dataCompany;
      if (this.company) {
        this.companyInformation.controls.name.setValue(this.company.name);
        this.companyInformation.controls.siret.setValue(this.company.siret);
        this.companyInformation.controls.summary.setValue(this.company.summary);
        this.companyInformation.controls.website.setValue(this.company.website);
        this.companyInformation.controls.phone.setValue(this.company.phone);
        this.companyInformation.controls.address.setValue(this.company.address);
        this.companyInformation.controls.facebook.setValue(this.company.facebook);
        this.companyInformation.controls.linkedIn.setValue(this.company.linkedIn);
      }
    });
    this.companyInformation = this.formBuilder.group({
      name: ['', [Validators.required]],
      siret: ['', [Validators.required]],
      summary: [''],
      website: [''],
      phone: ['', [Validators.pattern(this.phoneNumberPattern)]],
      address: [''],
      facebook: [''],
      linkedIn: [''],
    });
  }


  public saveCompanyInformations(): void {
    const sendData = new FormData();
    sendData.append('name', this.companyInformation.controls.name.value);
    sendData.append('siret', this.companyInformation.controls.siret.value);
    sendData.append('summary', this.companyInformation.controls.summary.value);
    sendData.append('website', this.companyInformation.controls.website.value);
    sendData.append('phone', this.companyInformation.controls.phone.value);
    sendData.append('address', this.companyInformation.controls.address.value);
    sendData.append('facebook', this.companyInformation.controls.facebook.value);
    sendData.append('linkedIn', this.companyInformation.controls.linkedIn.value);
    sendData.append('image', this.companyLogo);
    this.companyService.updateCompany(sendData, this.company.id).subscribe((data) => {
      this.snack.info('Infos à jour !');
      window.location.reload();
      this.router.navigateByUrl('/jobs');
    }, (err) => {
      this.snack.error('erreur');
    });
  }
  // validateFirstBlock() {
  //   this.isFirstBlockErrorShow = true;
  // }
  // validateSecondBlock() {
  //   this.isSecondBlockErrorShow = true;
  // }
  // public onPersonEdit(e: any): void {
  //   this.companyLogo = e.target.files[0];
  // }



  public onSelectFile(event): void {
    if (event.target.files && event.target.files[0]) {
      this.companyLogo = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e: any) => {
        this.url = e.target.result;
      };
    }
  }
  public delete(): void {
    this.url = null;
  }
}
