<div class="main-header-banner">
  <div class="header-banner">
    <img src="assets/image/compare-bg.jpg">
  </div>
</div>
<div class="apply-empl-pro-sec apply-dasbord">
  <div class="col-12 no-padding">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box card">
      <div class="col-12 verbatims card-body">
        <div class="col-12 mt-0 mb-2 btn-c">
          <button class="btn btn-primary btn-rounded fright" (click)="toggleShowAddCampaign()" >{{!isAddCampaign ? '+ Ajouter une campagne' : '- Annuler l\'ajout'}}</button>
          <ng-form [formGroup]="addWidgetForm" *ngIf="isAddCampaign" class="create-form row col-lg-6 offset-lg-3 col-12 offset-0 new-campaign" autocomplete="off" >
            <mat-form-field appearance="none" style="width: 100%;">
              <input class="login-input mb-4" matInput placeholder="La PunchLine pour votre widget (requis)*"
              formControlName="punchLine">
              <table style="width:90%; margin:auto">
                <tr>
                  <td>Faire apparaître les avis audio des clients sur votre widget</td>
                  <td style="text-align:right">
                    <input type="checkbox"
                    class="checkbox-form"
                    (change)="setActive($event)"/>
                  </td>
                </tr>
              </table>
            </mat-form-field>
            <div fxLayoutAlign="space-between center" class="margin-auto">
              <div></div>
              <button class="btn btn-secondary btn-rounded" (click)="onSubmit()">Créer la campagne widget</button>
              <app-loader  class="pre_loader" *ngIf="isPreLoader == true"></app-loader>
          </div>
          </ng-form>
        </div>
        <div class="verbatim-selector col-lg-4 col-md-6 col-sm-6 col-12 mt-0 offset-sm-3 offset-md-3 offset-lg-4">
          <h4 class="col-12 text-center mb-3">Sélectionner une campagne widget :</h4>
          <mat-select [ngClass]="selectedCampaignValue" panelClass="mat-select-campaign" class="mat-select-campaign" id="campaign" placeholder="--Veuillez sélectionner une campagne--" (selectionChange)="changeCampaign($event.value)">
            <mat-option *ngFor="let campaign of campaigns" [value]="campaign.id">
              "{{campaign.jobName}}" du {{campaign.createdAt}}
            </mat-option>
          </mat-select>
        </div>
        <div *ngIf="isRadarChartLoaded" class="col-10 offset-1 radarChart mt-4 mb-4">
          <div class="bg-light head-graph col-12"><h5><i>Graphique Labels</i></h5></div>
          <div class="col-12 text-center mt-3 mb-3">
            <div style="display: block;">
              <canvas ngChartjs
                [datasets]="radarChartData"
                [options]="radarChartOptions"
                [labels]="radarChartLabels"
                [chartType]="radarChartType">
              </canvas>
            </div>
          </div>
        </div>
        <div *ngIf="isRadarChartMacroLoaded" class="col-10 offset-1 radarChart mt-4 mb-4">
          <div class="bg-light head-graph col-12"><h5><i>Graphique Macro Thèmes</i></h5></div>
          <div class="col-12 text-center mt-3 mb-3">
            <div style="display: block;">
              <canvas ngChartjs
                [datasets]="radarChartMacroData"
                [options]="radarChartOptions"
                [labels]="radarChartMacroLabels"
                [chartType]="radarChartType">
              </canvas>
            </div>
          </div>
        </div>
        <div *ngIf="isRadarChartLabelTLoaded" class="col-10 offset-1 radarChart mt-4 mb-4">
          <div class="bg-light head-graph col-12"><h5><i>Graphique Thèmes Label</i></h5></div>
          <div class="col-12 text-center mt-3 mb-3">
            <div style="display: block;">
              <canvas ngChartjs
                [datasets]="radarChartLabelTData"
                [options]="radarChartOptions"
                [labels]="radarChartLabelTLabels"
                [chartType]="radarChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--<div class="row col-12 no-padding">
      <div *ngIf="isRadarChartLoaded" class="card col-6 no-padding">
        <div class="card-body">
          <div class="row radarChart mt-4 mb-4">
            <div class="bg-light head-graph col-10 offset-1">
              <h5><i>Graphique Labels</i></h5>
            </div>
            <div class="col-12 text-center mt-4 mb-2 no-padding">
              <div style="display: block;">
                <canvas ngChartjs [datasets]="radarChartData" [options]="radarChartOptions" [labels]="radarChartLabels"
                  [chartType]="radarChartType">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isRadarChartMacroLoaded" class="card col-6 no-padding">
        <div class="card-body">
          <div class="row radarChart mt-4 mb-4">
            <div class="bg-light head-graph col-10 offset-1">
              <h5><i>Graphique Macro Thèmes</i></h5>
            </div>
            <div class="col-12 text-center mt-4 mb-2 no-padding">
              <div style="display: block;">
                <canvas ngChartjs [datasets]="radarChartMacroData" [options]="radarChartOptions"
                  [labels]="radarChartMacroLabels" [chartType]="radarChartType">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isRadarChartLabelTLoaded" class="card col-6 no-padding">
        <div class="card-body">
          <div class="row radarChart mt-4 mb-4">
            <div class="bg-light head-graph col-10 offset-1">
              <h5><i>Graphique Thèmes Label</i></h5>
            </div>
            <div class="col-12 text-center mt-4 mb-2 no-padding">
              <div style="display: block;">
                <canvas ngChartjs [datasets]="radarChartLabelTData" [options]="radarChartOptions"
                  [labels]="radarChartLabelTLabels" [chartType]="radarChartType">
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->


  </div>
</div>

