import { Component, HostListener, NgZone, OnInit, AfterViewInit } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Role } from '../../../model/role';
import { AuthenticationService } from '../../../service/authentication/authentication.service';
import { User } from '../../../model/user';
import { environment } from '../../../../environments/environment';
import { SharedServiceService } from '../../../shared/shared-service.service';
import { Platform } from '@angular/cdk/platform';
import { ContactUsComponent } from '../../contact-us/contact-us.component'
import { MatDialog } from '@angular/material/dialog';
//import { CompanyJobMultipleComponent } from '../../company/company-job-multiple/company-job-multiple.component';
import { CompanyService } from '../../../service/company/company.service';
import {AvailableCountry} from '../../../model/availableCountry';
import { Router } from '@angular/router';
import { HttpClientService } from '../../../service/http-client/http-client.service';
import { FormControl } from '@angular/forms';

interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {name: 'jQuery', src: '../../../../assets/sonorize-template/assets/js/jquery.min.js'},
  {name: 'bootstrap', src: '../../../../assets/sonorize-template/assets/js/bootstrap.bundle.min.js'},
  {name: 'metismenu', src: '../../../../assets/sonorize-template/assets/js/metismenu.min.js'},
  {name: 'jQueryUi', src: '../../../../assets/sonorize-template/assets/js/jquery-ui.min.js'},
  {name: 'jQueryQr', src: 'https://larsjung.de/jquery-qrcode/latest/jquery-qrcode-0.18.0.js'},
  {name: 'waves', src: '../../../../assets/sonorize-template/assets/js/waves.js'}
];


@Component({
  selector: 'app-header',
  templateUrl: './sonorizeheader.component.html',
  styleUrls: [
    '../../../../assets/sonorize-template/plugins/jvectormap/jquery-jvectormap-2.0.2.css',
    '../../../../assets/sonorize-template/plugins/lightpick/lightpick.css',
    '../../../../assets/sonorize-template/assets/css/bootstrap.min.css',
    '../../../../assets/sonorize-template/assets/css/jquery-ui.min.css',
    '../../../../assets/sonorize-template/assets/css/icons.min.css',
    '../../../../assets/sonorize-template/assets/css/metisMenu.min.css',
    '../../../../assets/sonorize-template/assets/css/app.min.css',
    //'../../../../assets/sonorize-template/assets/scss/app.scss',
    './sonorizeheader.component.css',
  ],
  animations: []
})

export class SonorizeHeaderComponent implements OnInit, AfterViewInit {
  isOnTop = true;
  public user: User;
  public image: string;
  public showPlayer = true;
  public showFooter = true;
  public isIOSMobileDevice = false;
  public availableCountries = Array<AvailableCountry>();
  public selectedCountryValue = "FR";
  public selectedCountryControl: FormControl;
  public isHome: boolean;
  public isSonorizeDashboard: boolean;
  public currentSection:string = "dashboard";
  public currentPage:string = "campaigns";

  company: any;
  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private zone: NgZone,
    private authenticationService: AuthenticationService,
    private plt: Platform,
    private sharedService: SharedServiceService,
    private matDialog: MatDialog,
    private companyService: CompanyService,
    private httpClientService: HttpClientService,
    private router: Router
  ) {

    router.events.subscribe((val) => {
      // see also
      this.applyCurrentPage();
    });
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
    this.authenticationService.user.subscribe(x => {
      console.log(!x);
      this.user = x;
    });
    // this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // console.log(this.user);
      this.image = environment.imageBaseUrl + this.user.image;
    } else {
      this.image = 'assets/image/icon_like.svg';
    }

    this.load('jQuery', 'bootstrap', 'metismenu', 'jQueryUi', 'waves').then(data => {
      console.log('script loaded ', data);

      this.load('jQueryQr').then(data => {
        console.log('script loaded ', data);
      }).catch(error => console.log(error));

    }).catch(error => console.log(error));
    // console.log(this.image);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
    /*this.scrollDispatcher.scrolled().subscribe((event: CdkScrollable) => {
      console.log(event);
      if (event === undefined) {
        return;
      } else {
        const scroll = event.measureScrollOffset('top');
        let newIsOnTop = this.isOnTop;

        newIsOnTop = scroll <= 0;

        if (newIsOnTop !== this.isOnTop) {
          this.zone.run(() => {
            this.isOnTop = newIsOnTop;
          });
        }
      }
    });*/
    this.sharedService.navigationToDetails.subscribe((result) => this.showFooter = result);
    this.sharedService.navigation.subscribe((data) => {
      this.showPlayer = data && (!this.isHome && !this.isSonorizeDashboard);
    });
    if (this.plt.IOS) {
      this.isIOSMobileDevice = true;
    }
  }

  public linkToAudios() {
    if(this.company)
      window.open('https://api.gotaf.fr/sonorize/show-reviews/'+this.company.id, '_blank')
  }

  public applyCurrentPage() {
    this.currentSection = "dashboard";
    if(this.router.url.includes('/sonorize/reviews')) {
      this.currentPage = "reviews";
    }
    else if(this.router.url.includes('/sonorize/parameters')) {
      this.currentSection = "profile";
      this.currentPage = "parameters";
    }
    else if(this.router.url.includes('/sonorize')) {
      this.currentPage = "campaigns";
    }
  }

  ngOnInit(): void {
    this.applyCurrentPage();
    if(this.user) {
      this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
        this.company = data;
      });
    }
    this.isHome = this.router.url === '/';
    this.isSonorizeDashboard = this.router.url.includes('/sonorize');
    this.showPlayer = !this.isSonorizeDashboard;
    // console.log(this.user);
    /*const element = document.querySelector('#header-desktop');
    element.classList.add('header-transparent');*/

    this.availableCountries = [
      { name :""/*"Annonces France"*/, imgurl:"assets/image/flag/fr.png", reference:"FR" },
      { name :""/*"Annonces Sénégal"*/, imgurl:"assets/image/flag/sn.png", reference:"SN" },
      { name :""/*"Annonces Usa"*/, imgurl:"assets/image/flag/us.png", reference:"US" },
      { name :""/*"Annonces Bénin"*/, imgurl:"assets/image/flag/bj.png", reference:"BJ" },
      { name :""/*"Annonces Togo"*/, imgurl:"assets/image/flag/tg.png", reference:"TG" },
      { name :""/*"Annonces Côte d'Ivoire"*/, imgurl:"assets/image/flag/ci.png", reference:"CI" }
    ];
    this.httpClientService.bheadersObservable.subscribe(headers => {
      this.selectedCountryValue = headers.get('Country');
    });
    console.log(this.selectedCountryValue);
    this.selectedCountryControl = new FormControl(this.selectedCountryValue);

  }

  public onCountryChange(value:string) {
    this.httpClientService.modifyCountry(value);
    console.log(value);
    // #TODO refresh component (for refreshing results with new value)
    /*let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });*/
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }


  public get isCompany(): boolean {
    return this.user && this.user.role === Role.Company;
  }

  public logout(): void {
    this.authenticationService.logout('sonorize');
  }

  public publishJobs(): void {
    // this.companyService.getCompanyByUser(this.authenticationService.userValue.id).subscribe((data) => {
    //   this.company = data;
    //   if (data.jobSolde === 0 || data.jobSolde === null) {
    //     this.router.navigate([`/subscription-plan/${this.company.id}`]);
    //   } else {
    //     this.matDialog.open(CompanyJobMultipleComponent, { width: '450px', height: '300px' });
    //   }

    // }, ignore => {
    //   console.log('error when loading lastJobs');
    // });

    this.matDialog.open(ContactUsComponent, { width: '470px', height: '470px' });
  }


  @HostListener('window:scroll', ['$event'])

  public onWindowScroll(): void {
    /*const element = document.querySelector('#header-desktop');
    // console.log(element);
    // console.log(element.clientHeight);
    if (window.pageYOffset > element.clientHeight) {
      element.classList.remove('header-transparent');
      element.classList.add('header-fixed');
    } else {
      element.classList.add('header-transparent');
      element.classList.remove('header-fixed');
    }*/
  }

  private scripts: any = {};

  load(...scripts: string[]) {
      var promises: any[] = [];
      scripts.forEach((script) => promises.push(this.loadScript(script)));
      return Promise.all(promises);
  }

  loadScript(name: string) {
      return new Promise((resolve, reject) => {
          //resolve if already loaded
          if (this.scripts[name].loaded) {
              resolve({script: name, loaded: true, status: 'Already Loaded'});
          }
          else {
              //load script
              let script = document.createElement('script');
              script.type = 'text/javascript';
              script.src = this.scripts[name].src;
              script.onload = () => {
                  this.scripts[name].loaded = true;
                  resolve({script: name, loaded: true, status: 'Loaded'});
              };
              script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
              document.getElementsByTagName('head')[0].appendChild(script);
          }
      });
  }
}

