
<div class="main-header-banner">
  <div class="header-banner">
    <img src="assets/image/compare-bg.jpg">
  </div>
</div>
<div class="apply-empl-pro-sec apply-dasbord">
  <div class="col-12 no-padding">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="apply-emp-profile-box card">

       <!-- Page Content -->
        <div class="container parameters">
          <form [formGroup]="registerForm" class="login-form"  autocomplete="off" >
            <div class="d-lg-inline-flex">
              <div class="col-lg-5 col-12">

                <mat-form-field class="spec" appearance="none" style="width: 100%; min-width: 300px; height:120px;background:#F5F5F5">
                  <mat-toolbar>
                    <!-- Readonly Input to show File names -->
                    <img class="i-preview" src="{{registerForm && registerForm.controls.logo.value != '' ? registerForm.controls.logo.value : initLogoUrl}}" alt="logo"/>
                    <!-- Browse Button -->
                    <button type="button" class="btn btn-main btn-rounded mt-3 mr-3 sp" style="min-width:150px; float: right;" mat-raised-button (click)="UploadFileInput.click()">
                      Choisir un Logo
                    </button>
                  </mat-toolbar>
                  <!-- Fetch selected filed on change -->
                  <input hidden matInput type="file" #UploadFileInput id="logo" (change)="logoChangeEvent($event)"
                    name="logo" multiple="multiple" accept="image/*" />
                  <input hidden matInput type="text" formControlName="logo"/>
                </mat-form-field>
                <mat-form-field class="spec" appearance="none" style="width: 100%; min-width: 300px;  height: 120px;background:#F5F5F5">
                  <mat-toolbar>
                    <!-- Readonly Input to show File names -->
                    <img class="i-preview" src="{{registerForm && registerForm.controls.background.value != '' ? registerForm.controls.background.value : initBackgroundUrl}}" alt="fond"/>
                    <!-- Browse Button -->
                    <button type="button" class="btn btn-main btn-rounded mt-3 mr-3 sp" style="min-width:150px; float: right" mat-raised-button (click)="UploadFileInput2.click()">
                      Choisir un Fond
                    </button>
                  </mat-toolbar>
                  <!-- Fetch selected filed on change -->
                  <input hidden matInput type="file" #UploadFileInput2 id="background"
                    (change)="backgroundChangeEvent($event)" name="background" multiple="multiple" accept="image/*" />
                    <input hidden matInput type="text" formControlName="background"/>
                </mat-form-field>

                <mat-form-field  style="width:100%;" class="form-field mt-4">
                  <label class="text-label">Couleur dominante widget (cliquez sur le carré pour choisir) &nbsp;</label>
                  <input matInput type="color" (change)="setColor($event)" [ngClass]="{'no-color': !selectedColor}" value="{{selectedColor}}" required placeholder="">
                </mat-form-field>
              </div>

              <div class="col-lg-6 offset-lg-1 col-12">
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><mat-icon matPreffix
                        class="icon-class">person</mat-icon></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Utilisateur" formControlName="name" aria-label="Utilisateur"
                    aria-describedby="basic-addon1">
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><mat-icon matPreffix
                        class="icon-class">create</mat-icon></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Raison sociale" formControlName="companyName"
                    aria-label="Raison Sociale" aria-describedby="basic-addon1">
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><mat-icon matPreffix
                        class="icon-class">contact_mail</mat-icon></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Email" formControlName="email" aria-label="Email"
                    aria-describedby="basic-addon1">
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><mat-icon matPreffix
                        class="icon-class">vpn_key</mat-icon></span>
                  </div>
                  <input type="password" class="form-control" matInput placeholder="Mot de passe" formControlName="password"
                    aria-label="Mot de passe" aria-describedby="basic-addon1">
                </div>
              </div>
            </div>
            <div class="col-12 text-center mt-4">
              <button class="btn btn-secondary btn-rounded" (click)="onSubmit()">Valider les modifications</button>
            </div>
          </form>

        </div>

    </div>
  </div>
</div>

